import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { AssetJobResponse, JobsEndpoints, JobType } from './jobs.model';

@Injectable({ providedIn: 'root' })
export class JobsApiService {
  private apiService = inject(ApiService);

  getFile = (url: string) => this.apiService.getAbsoluteUrl<ApiResponse<any>>(url).pipe(GetResponseData);

  getJobsTypes = (): Observable<JobType[]> =>
    this.apiService.get<ApiResponse<JobType[]>>(`${JobsEndpoints.JOB_TYPES}`).pipe(GetResponseData);

  getAssetJobs = (assetUuid: string): Observable<AssetJobResponse> =>
    this.apiService
      .get<ApiResponse<AssetJobResponse>>(`${JobsEndpoints.ASSET_JOBS}/${assetUuid}`)
      .pipe(GetResponseData);
}
