import { Injectable, inject } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';
import { SelectOption, Type } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class DepartmentService {
  private fieldOptionsService = inject(FieldsOptionsService);

  departments$ = this.fieldOptionsService.getTypeByName('department');

  departmentOptions$ = this.departments$.pipe(
    map<Type, SelectOption[]>((type) => type.fields.map((field) => ({ key: field.uuid, label: field.label }))),
  );
}
