import { modelContext } from '../cart/models/cart.model';
import { VideoAnalysisResultsUrl } from '../media-insights/media-insights.model';

export enum JobsEndpoints {
  ROOT = 'jobs',
  ASSET_JOBS = 'jobs/asset',
  JOB_TYPES = 'job/types',
}

export interface JobType {
  uuid: string;
  name: string;
  type: JobTypeGroup;
  context: JobTypeContext;
  config: JobTypeConfig;
  dependsOn?: JobTypeContext;
}

export type JobTypeConfig = JobTypeConfigItem[];

export type JobTypeConfigItem = {
  model?: string;
  service?: JobTypeService;
  options?: JobTypeOptionsItem[];
};

export type JobTypeService = 'Whisper' | 'OpenAI' | 'AWS Rekognition' | 'Hybrik';

export type JobTypeOptionsItem = {
  name: string;
  type: JobTypeItemOptionsFieldType;
  required: boolean;
  default?: any;
  values?: string[] | number[] | { [key: string]: string }[];
  min?: number;
  max?: number;
};

export type JobTypeItemOptionsFieldType = 'string' | 'number' | 'float' | 'boolean' | 'select' | 'multi-select';
export type JobStatus = 'created' | 'scheduled' | 'in_progress' | 'completed' | 'failed';
export type JobSource = 'order' | 'asset';
export type JobTypeGroup = 'ai' | 'transcode' | 'analyse_video' | 'analyse_audio';

export interface AssetJobResponse {
  merged_results_url: string;
  jobs: jobData[];
}

export interface jobData {
  type: modelContext;
  status: JobStatus;
  json_url: string | null;
  vtt_url: string | null;
  result_urls: VideoAnalysisResultsUrl[];
  target_language?: string;
  created_at: string;
  updated_at: string;
  name: string;
}

export enum JobTypeContext {
  LABEL_DETECTION = 'label-detection',
  FACE_DETECTION = 'face-detection',
  CELEBRITY_RECOGNITION = 'celebrity-recognition',
  VIDEO_SEGMENT_DETECTION = 'video-segment-detection',
  VIDEO_BLACK_DETECTION = 'video-black-detection',
  VIDEO_BORDERS_DETECTION = 'video-borders-detection',
  VIDEO_INTERLACING_ANALYSE = 'video-interlacing-analyse',
  TEXT_DETECTION = 'text-detection',
  CONTENT_MODERATION = 'content-moderation',
  TRANSLATE_SUBTITLES = 'translate-subtitles',
  TRANSLATE_VIDEO = 'translate-video',
  TRANSCRIBE_VIDEO = 'transcribe-video',
  VIDEO_SUMMARY = 'video-summary',
  LANGUAGE_DETECTION = 'language-detection',
  VIDEO_STATISTICS = 'video-statistics',
  AUDIO_STATISTICS = 'audio-statistics',
  REGENERATE_PROXY = 'regenerate-proxy',
}
