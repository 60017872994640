import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FlatOrderViewModel, OrdersDataSource } from '../../logic/order-results-ds';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FiltersOrderForm } from '../../logic/filters-order-form';
import {
  ActionContextLess,
  DataAction,
  DataPresentationHeaderComponent,
  DataValueColumn,
  DefaultMultiViewType,
  GridAdvancedAdditionalInfo,
  GridAdvancedMetadata,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIConfirmationDialogService,
  UILayoutModule,
  UIButtonModule,
} from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, defer, Observable, Subject, switchMap } from 'rxjs';
import { ColumnsFetcherService, ResultsActions } from '@vdms-hq/asset-results';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import {
  defaultOrdersColumns,
  defaultSalesForceOrdersColumns,
  ordersColumns,
  ordersMetadata,
} from '../../../details/logic/view-configurations';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ActivatedClientService, Permission, WithPermissions, ActivatedClientModule } from '@vdms-hq/activated-client';
import { salesForceFilters, salesForceFiltersOnlyMine } from '../../../logic/config';
import { ColumnsConfigService } from '../../logic/columns-config.service';
import { ColumnsSettingsScopes } from '@vdms-hq/firebase-contract';
import { OrderService, OrdersType, PaginationAPIModel as Pagination } from '@vdms-hq/api-contract';
import { OrderActionsProperties } from '../../../details/logic/models';
import { SelectOption, SelectOptionKey } from '@vdms-hq/shared';
import { OrderActionsService } from '../../../details/logic/order-actions.service';

@Component({
  selector: ' vdms-hq-orders-results[tableType][columnsSettingsScope]',
  templateUrl: './results.component.html',
  imports: [
    CommonModule,
    DynamicFiltersModule,
    DataPresentationHeaderComponent,
    MultipleDataPresentationComponent,
    TranslateModule,
    UILayoutModule,
    UIButtonModule,
    ActivatedClientModule,
  ],
  standalone: true,
})
export class ResultsComponent extends WithPermissions() implements OnDestroy {
  @Input() actions: DataAction<FlatOrderViewModel>[] = [];
  @Input() tableType!: TABLE_TYPE;
  @Input() columnsSettingsScope!: ColumnsSettingsScopes;
  @Input() filtersConfig: DynamicFilterInput[] = [];
  @Input() title = 'common.orders.title';
  @Output() changeDefaultView: EventEmitter<DefaultMultiViewType> = new EventEmitter<DefaultMultiViewType>();

  @Output() action = new EventEmitter<{
    item?: FlatOrderViewModel;
    key: string;
  }>();
  #destroy$ = new Subject<void>();

  protected readonly OrderActionsProperties = OrderActionsProperties;
  protected readonly ResultsActions = ResultsActions;

  configuration$: Observable<MultipleViewConfiguration<FlatOrderViewModel>> = combineLatest({
    client: this.activatedClientService.clientDefinite$,
    permissions: this.activatedClientService.permissions$,
    defaultView: defer(() => this.viewSettingsService.defaultViewFor$(this.tableType)),
    enabledColumns: defer(() => this.columnsConfigService.getEnabledColumns$(this.columnsSettingsScope)),
  }).pipe(
    map(({ client, permissions, defaultView, enabledColumns }) => {
      let metadata: GridAdvancedMetadata<FlatOrderViewModel>[] = [];
      let additionalInfo: GridAdvancedAdditionalInfo | undefined = undefined;
      if (client.integrations.salesforce) {
        this.filtersConfig = salesForceFiltersOnlyMine;
        if (
          permissions.some((perm) =>
            [Permission.EDIT_ORDERS, Permission.APPROVE_ORDERS, Permission.BROWSE_SHARED_ORDERS].includes(perm),
          )
        ) {
          this.filtersConfig = salesForceFilters;
        }
        metadata = defaultSalesForceOrdersColumns
          .map((metadata) => ordersMetadata.find((item) => item.valuePath === metadata))
          .filter((item) => !!item);

        additionalInfo = {
          valuePath: 'deliveryStatus',
          viewFormat: {
            pills: {
              'common.orders.delivery.getty_images_failed': 'failed',
              'common.orders.delivery.delivered': 'done',
              'common.orders.delivery.failed': 'failed',
            },
            modifiers: {
              translate: true,
            },
          },
        };
      } else {
        metadata = defaultOrdersColumns
          .map((metadata) => ordersMetadata.find((item) => item.valuePath === metadata))
          .filter((item) => !!item);

        additionalInfo = {
          valuePath: 'props.status',
          viewFormat: {
            pills: {
              downloaded: 'done',
              expired: 'orange',
              new: '',
              default: 'yellow',
            },
          },
        };
      }
      const enabledActions = this.actions?.filter((item) => !!item) as DataAction<FlatOrderViewModel>[];

      return {
        multiView: {
          defaultView: defaultView,
        },
        tableAdvanced: {
          actions: enabledActions,
          columnsEnabled: ['select', ...enabledColumns],
          columns: [{ id: 'select', type: 'select' }, ...ordersColumns],
          settingsAction: {
            id: 'settings',
            label: 'common.global.edit_columns',
            icon: 'settings',
            color: 'transparent',
          },
        },
        gridAdvanced: {
          content: {
            titlePath: 'title',
            metadata: metadata,
            additionalInfo: additionalInfo ?? undefined,
          },
          image: {
            bgPath: 'background',
            bgHoverPath: 'background',
          },
          actions: enabledActions,
        },
        gridTiles: {
          layout: {
            columns: 'up-to-6',
          },
          image: {
            bgPath: 'background',
            bgHoverPath: 'background',
          },
          content: {
            titlePath: 'title',
          },
          actions: enabledActions,
        },
      };
    }),
  );

  headerActions: ActionContextLess[] = [
    {
      key: ResultsActions.EXPORT,
      label: 'pages.assets_list.export',
      icon: 'forward_to_inbox',
    },
  ];

  downloadManyDisabled$ = this.dataSource.selection.entities$.pipe(
    filter((selection) => !!selection),
    map((orders) => !orders.some((order) => order.isDelivered)),
  );

  downloadManyLoading$ = this.orderActionsService.downloadManyLoading$;

  constructor(
    public dataSource: OrdersDataSource,
    public filtersForm: FiltersOrderForm,
    private confirmationDialog: UIConfirmationDialogService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private activatedClientService: ActivatedClientService,
    private viewSettingsService: ViewSettingsService,
    private columnsConfigService: ColumnsConfigService,
    private columnsFetcherService: ColumnsFetcherService,
    private OrderApiService: OrderService,
    private orderActionsService: OrderActionsService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  emitAction($event: { item: FlatOrderViewModel; key: string }) {
    this.action.emit($event);
  }

  handleHeaderAction($event: { key: string; item?: FlatOrderViewModel }) {
    switch ($event.key) {
      case ResultsActions.EXPORT:
        this.confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            withLatestFrom(
              this.columnsConfigService.columnDefinitionsForOrderSettings$,
              this.columnsFetcherService.userConfig$(this.columnsSettingsScope),
            ),
            map(([, orderColumnsDefinitions, userConfig]) => {
              const enabled: string[] | SelectOption<SelectOptionKey>[] =
                userConfig.length > 0 ? userConfig : orderColumnsDefinitions;
              return enabled.map((id) => ordersColumns.find((def) => def.id === id)).filter((def) => !!def);
            }),
            map((fields) =>
              fields.map((field) => ({
                label: this.translateService.instant((field as DataValueColumn).label),
                value: (field as DataValueColumn).valuePath,
              })),
            ),
            withLatestFrom(this.dataSource.filtersValues$, this.dataSource.pageIndex$, this.dataSource.pageSize$),
            switchMap(([fields, filters, pageIndex, pageSize]) => {
              const filtersObject = {
                'delivery-status': filters.deliveryStatus,
                'name-subject': filters.nameSubject,
                'download-status': filters.downloadStatus,
                'orders-type': filters.ordersType as OrdersType,
                'date-from': filters.txDate?.from.toISOString(),
                'date-to': filters.txDate?.to.toISOString(),
                status: filters.status,
              };
              const paginationObject = Pagination.create({
                page: pageIndex,
                perPage: pageSize,
                ...filters.sort,
              });
              return this.OrderApiService.export(fields, filtersObject, paginationObject);
            }),
            tap(() =>
              this.toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            ),
          )
          .subscribe({
            error: () => {
              this.toastService.error({
                id: 'export',
                message: 'notifications.export.failed',
              });
            },
          });
        break;
    }
  }
}
