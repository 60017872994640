import { NgModule, Compiler } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigurationGuard, Permission, RequiresPermissionsGuard } from '@vdms-hq/activated-client';
import { AuthOnlyGuard, GuestOnlyGuard } from '@vdms-hq/auth';
import { COLLECTIONS_ROUTER_BASE } from '@vdms-hq/collections';
import { DASHBOARDS_ROUTER_BASE } from '@vdms-hq/dashboards';
import { DELIVERY_DESTINATIONS_ROUTING } from '@vdms-hq/delivery-destinations';
import { LICENSE_PACKAGES_ROUTER_BASE } from '@vdms-hq/license-packages';
import { ORDERS_ROUTER_BASE } from '@vdms-hq/orders';
import { RIGHTS_CONTRACTS_ROUTER_BASE } from '@vdms-hq/rights-contracts';
import { RIGHTS_PARTNERS_ROUTER_BASE } from '@vdms-hq/rights-partners';
import { environment } from '../environments/environment';
import { SecuredComponent } from './pages/secured/secured.component';
import { InstallAsperaComponent } from './shared/view-components/install-aspera/install-aspera.component';
import { USER_SETTINGS_ROUTER_BASE } from '@vdms-hq/user-settings';
import { ArchivedReleaseNotesComponent, RELEASE_NOTES_ROUTER_BASE } from '@vdms-hq/release-notes';
import { ADMIN_ROUTING } from '@vdms-hq/activated-client-admin';
import { DynamicModuleFactoryService, SelectOptionsTableComponent } from '@vdms-hq/select-options-editor';
import { EGRESS_DASHBOARD_ROUTER_BASE } from '@vdms-hq/egress-stats';

const title = `Vida Content OS v${environment.version} ${new Date().getFullYear()}`;

const dynamicModuleFactoryService = new DynamicModuleFactoryService(new Compiler());

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'ui-playground',
    title,
    loadChildren: () => import('@vdms-hq/ui').then((m) => m.UIPlaygroundModule),
  },
  {
    path: 'login',
    title,
    canActivate: [GuestOnlyGuard],
    loadChildren: () => import('@vdms-hq/login').then((m) => m.LoginModule),
  },
  {
    path: 'download',
    title,
    loadChildren: () => import('./guest-download/guest-download.module').then((m) => m.GuestDownloadModule),
  },
  {
    path: 'order',
    title,
    loadChildren: () => import('./guest-download/guest-download.module').then((m) => m.GuestDownloadModule),
  },
  {
    path: '',
    title,
    canActivate: [AuthOnlyGuard],
    data: {
      permissions: [Permission.GENERAL_LOGIN_TO_VIDA],
      operator: 'every',
      breadcrumb: {
        name: 'Home',
        path: '/',
        clickable: false,
      },
    },
    component: SecuredComponent,
    children: [
      {
        path: 'player/:uuid',
        redirectTo: 'asset/:uuid',
      },
      {
        path: 'stage/:stage',
        redirectTo: 'assets/:stage',
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS] },
      },
      {
        path: 'ingest/:uuid',
        redirectTo: 'asset/:uuid/ingest',
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS_INGEST] },
      },
      {
        path: 'archive/:uuid',
        redirectTo: 'asset/:uuid/archive',
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS_ARCHIVE] },
      },
      {
        path: 'activated-client-admin',
        redirectTo: ADMIN_ROUTING.ADMIN_RAW,
      },
      {
        path: 'search',
        redirectTo: 'browse',
        pathMatch: 'full',
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS],
          breadcrumb: {
            name: 'Search',
            path: 'search',
            clickable: true,
          },
        },
      },
      {
        path: 'upload-aspera',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.ASSET_UPLOAD] },
        loadChildren: () =>
          import('./upload-aspera/upload-aspera-routing.module').then((m) => m.UploadAsperaRoutingModule),
      },
      {
        path: 'collection/:uuid',
        redirectTo: 'collections/:uuid',
        pathMatch: 'full',
      },
      {
        path: 'maps',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_ASSETS_ON_MAP] },
        loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
      },
      {
        path: 'import-csv',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.IMPORT_CSV] },
        loadChildren: () => import('@vdms-hq/metadata-import').then((m) => m.MetadataImportModule),
      },
      {
        path: 'pathe-import',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.SHOPPING_CART] },
        loadChildren: () => import('./pathe-import/pathe-import.module').then((m) => m.PatheImportModule),
      },
      {
        path: 'home',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA] },
        loadChildren: () => import('./dashboard/dashboard-routing.module').then((m) => m.dashboardRoutes),
      },
      {
        path: 'browse',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          requirePermissions: [Permission.GENERAL_LOGIN_TO_VIDA],
          permissions: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_STOREFRONT, Permission.BROWSE_OWN_ASSETS],
          operator: 'some',
          breadcrumb: {
            name: 'Assets',
            path: '/browse/all',
            clickable: true,
          },
        },
        loadChildren: () => import('./browse/browse.module').then((m) => m.BrowseModule),
      },
      {
        path: 'asset',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          requirePermissions: [Permission.GENERAL_LOGIN_TO_VIDA],
          permissions: [
            Permission.BROWSE_ASSETS,
            Permission.BROWSE_ASSETS_STOREFRONT,
            Permission.BROWSE_OWN_ASSETS,
            Permission.BROWSE_SHARED_COLLECTIONS,
            Permission.BROWSE_COLLECTIONS,
          ],
          operator: 'some',
          breadcrumb: {
            name: 'Asset',
            path: 'asset',
            clickable: true,
          },
        },
        loadChildren: () => import('./asset-details/asset-details-routing').then((m) => m.AssetRoutingModule),
      },
      {
        path: 'storage-dashboard',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA] },
        loadChildren: () => import('./stats/stats.routes').then((m) => m.statsRoutes),
      },
      {
        path: EGRESS_DASHBOARD_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA] },
        loadChildren: () => import('@vdms-hq/egress-stats').then((m) => m.EGRESS_DASHBOARD_ROUTES),
      },
      {
        path: 'power-bi',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_POWER_BI] },
        loadChildren: () => import('@vdms-hq/power-bi').then((m) => m.PowerBiModule),
      },
      {
        path: 'policies',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_POLICIES] },
        loadChildren: () => import('@vdms-hq/policies').then((m) => m.PoliciesModule),
      },
      {
        path: ORDERS_ROUTER_BASE.ORDERS,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          requirePermissions: [Permission.GENERAL_LOGIN_TO_VIDA],
          permissions: [
            Permission.BROWSE_ALL_ORDERS,
            Permission.BROWSE_OWN_ORDERS,
            Permission.BROWSE_SHARED_ORDERS,
            Permission.APPROVE_ORDERS,
            Permission.BROWSE_ORDERS_TO_APPROVE,
            Permission.BROWSE_MEDIA_PULSE_ORDERS,
          ],
          operator: 'some',
          breadcrumb: {
            name: 'Orders',
            path: ['/', ORDERS_ROUTER_BASE.ORDERS],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/orders').then((m) => m.ordersRouting),
      },
      {
        path: ORDERS_ROUTER_BASE.ORDERS_MEDIA_PULSE,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_MEDIA_PULSE_ORDERS],
          breadcrumb: {
            name: 'Media Pulse Orders',
            path: ['/', ORDERS_ROUTER_BASE.ORDERS_MEDIA_PULSE],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/orders').then((m) => m.mediaPulseOrdersRouting),
      },
      {
        path: LICENSE_PACKAGES_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_LICENSED_PACKAGES],
          breadcrumb: {
            name: 'Licensed packages',
            path: ['/', LICENSE_PACKAGES_ROUTER_BASE.ROOT],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/license-packages').then((m) => m.licensePackagesRouting),
      },
      {
        path: RIGHTS_CONTRACTS_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_RIGHTS_CONTRACTS],
          breadcrumb: {
            name: 'Rights Contracts',
            path: ['/', RIGHTS_CONTRACTS_ROUTER_BASE.ROOT],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/rights-contracts').then((m) => m.rightsContractsRouting),
      },
      {
        path: RIGHTS_PARTNERS_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_RIGHTS_PARTNERS],
          breadcrumb: {
            name: 'Licensees',
            path: ['/', RIGHTS_PARTNERS_ROUTER_BASE.ROOT],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/rights-partners').then((m) => m.rightsPartnersRouting),
      },
      {
        path: ORDERS_ROUTER_BASE.SHARED_PACKS,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_SHARED_PACKS] },
        loadChildren: () => import('@vdms-hq/orders').then((m) => m.sharedPacksRouting),
      },
      {
        path: 'clients',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_GROUPS] },
        loadChildren: () => import('@vdms-hq/clients').then((m) => m.ClientsEditorModule),
      },
      {
        path: 'users',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_USERS] },
        loadChildren: () => import('@vdms-hq/users').then((m) => m.usersRoutes),
      },
      {
        path: 'client-users',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.USER_ADMIN_BROWSE] },
        loadChildren: () => import('@vdms-hq/client-user-admin').then((m) => m.CLIENT_USER_ROUTING),
      },
      {
        path: 'cart',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        canLoad: [ConfigurationGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.SHOPPING_CART],
          breadcrumb: {
            name: 'Cart',
            path: '/cart',
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/cart').then((m) => m.CartModule),
      },
      {
        path: 'cart-v3',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        canLoad: [ConfigurationGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.SHOPPING_CART],
          breadcrumb: {
            name: 'Cart V3',
            path: '/cart-v3',
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/advanced-checkout').then((m) => m.CART_ROUTES),
      },
      {
        path: 'cart-v2',
        redirectTo: '/cart',
      },
      {
        path: DASHBOARDS_ROUTER_BASE.DASHBOARDS,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_DASHBOARDS] },
        loadChildren: () => import('@vdms-hq/dashboards').then((m) => m.dashboardsRoutes),
      },
      {
        path: USER_SETTINGS_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard],
        loadChildren: () => import('@vdms-hq/user-settings').then((m) => m.userSettingsRoutes),
      },
      {
        path: DELIVERY_DESTINATIONS_ROUTING.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_DELIVERY_DESTINATIONS],
        },
        loadChildren: () => import('@vdms-hq/delivery-destinations').then((m) => m.destinationsRoutes),
      },
      {
        path: COLLECTIONS_ROUTER_BASE.COLLECTIONS,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_COLLECTIONS],
          breadcrumb: {
            name: 'My Collections',
            path: ['/', COLLECTIONS_ROUTER_BASE.COLLECTIONS],
            clickable: true,
          },
        },
        loadChildren: () => import('@vdms-hq/collections').then((m) => m.collectionsRoutes),
      },
      {
        path: `${COLLECTIONS_ROUTER_BASE.COLLECTIONS_LEGACY}`,
        redirectTo: `${COLLECTIONS_ROUTER_BASE.COLLECTIONS}`,
      },
      {
        path: `${COLLECTIONS_ROUTER_BASE.COLLECTIONS_LEGACY}/${COLLECTIONS_ROUTER_BASE.COLLECTIONS_COLLECTION}`,
        redirectTo: `${COLLECTIONS_ROUTER_BASE.COLLECTIONS}/${COLLECTIONS_ROUTER_BASE.COLLECTIONS_COLLECTION}`,
      },
      {
        path: 'fields-editor',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_FIELDS, Permission.EDIT_FIELDS] },
        loadChildren: () =>
          dynamicModuleFactoryService.createDynamicModule([{ path: '', component: SelectOptionsTableComponent }]),
      },
      {
        path: 'suppliers',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_SUPPLIERS] },
        loadChildren: () => import('@vdms-hq/suppliers').then((m) => m.SuppliersEditorModule),
      },
      {
        path: 'ratings',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_RATINGS] },
        loadChildren: () => import('@vdms-hq/ratings').then((m) => m.RatingsModule),
      },
      {
        path: 'systems',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_RATINGS] },
        loadChildren: () => import('@vdms-hq/systems').then((m) => m.SystemsModule),
      },
      {
        path: 'preview-request',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.BROWSE_PREVIEW_REQUESTS] },
        loadChildren: () => import('@vdms-hq/preview-request').then((m) => m.PreviewRequestModule),
      },
      {
        path: 'customized-checkout',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.SHOPPING_CART] },
        loadChildren: () => import('@vdms-hq/customized-checkout').then((m) => m.OrderCheckoutModule),
      },
      {
        path: 'support',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.CREATE_SUPPORT_REQUESTS] },
        loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
      },
      {
        path: RELEASE_NOTES_ROUTER_BASE.ROOT,
        canActivate: [AuthOnlyGuard],
        loadChildren: () => import('@vdms-hq/release-notes').then((m) => m.releaseNotesRoutes),
      },
      {
        path: RELEASE_NOTES_ROUTER_BASE.ARCHIVED,
        canActivate: [AuthOnlyGuard],
        component: ArchivedReleaseNotesComponent,
      },
      {
        path: ADMIN_ROUTING.ROOT,
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA] },
        loadChildren: () => import('@vdms-hq/activated-client-admin').then((m) => m.adminRoutes),
      },
      {
        path: 'browse-library',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: {
          permissions: [Permission.GENERAL_LOGIN_TO_VIDA],
          breadcrumb: {
            name: 'Assets Library',
            path: ['/', 'browse-library'],
            clickable: true,
          },
        },
        loadChildren: () => import('./browse-library/browse-library.routes').then((mod) => mod.browseLibraryRoutes),
      },
      {
        path: 'billing-reports',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA, Permission.CREATE_REPORTS] },
        loadChildren: () => import('@vdms-hq/billing-reports').then((m) => m.BillingReportsModule),
      },
      {
        path: 'install-aspera',
        canActivate: [AuthOnlyGuard, RequiresPermissionsGuard],
        data: { permissions: [Permission.GENERAL_LOGIN_TO_VIDA] },
        component: InstallAsperaComponent,
      },
      {
        path: 'error',
        loadChildren: () => import('@vdms-hq/error-handling').then((m) => m.ErrorHandlingModule),
      },
      {
        path: '**',
        redirectTo: 'error',
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
