import { GroupDefaultPolicyType, Policy } from '../policy/policy.model';

export const CLIENT_USER_ENDPOINT = 'user-admin';
export const CLIENT_USER_POLICIES_ENDPOINT = CLIENT_USER_ENDPOINT + '/policies';

export interface PaginationType {
  page?: number;
  perPage?: number;
  orderBy?: 'name' | 'email';
  orderDir?: 'ASC' | 'DESC';
}

export interface UserAdminSearchPagination extends PaginationType {
  text?: string;
  app?: VidaAppType;
}

export const VidaAppTypes = ['vida', 'launchpad', 'storefront', 'connect2'] as const;

export type VidaAppType = (typeof VidaAppTypes)[number];

export interface GetUserDetailsForUserAdminType extends GetUserCommonType {
  app_accesses: VidaAppType[];
}

export type ClientUserType = GetUserDetailsForUserAdminType;

export type ClientUserAdminViewModel = {
  props: ClientUserType;
  policiesNames: string[];
} & ClientUserType;

export class ClientUserAdminView implements ClientUserAdminViewModel {
  constructor(public props: ClientUserType) {}

  static fromClientUserType(clientUser: ClientUserType) {
    return new ClientUserAdminView(clientUser);
  }

  get id() {
    return this.props.id;
  }

  get uuid() {
    return this.props.uuid;
  }

  get name() {
    return this.props.name;
  }

  get email() {
    return this.props.email;
  }

  get permissions() {
    return this.props.permissions;
  }

  get app_accesses() {
    return this.props.app_accesses;
  }

  get policies() {
    return this.props.policies;
  }

  get policiesNames() {
    return this.props.policies.reduce((acc, policy) => {
      acc.push(policy.name);
      return acc;
    }, [] as string[]);
  }
}

export interface GetUserCommonType {
  id: number;
  uuid: string;
  policies: Policy[];
  permissions: ClientUserPermission[];
  name: string;
  email: string;
}

export interface PostUserToUserType {
  uuid: string;
  groups: string[];
  name: string;
  email: string;
  picture?: string;
  landing_page?: string;
  office_phone_number?: string;
  mobile_number?: string;
  office_address?: string;
  timezone?: string;
  date_format?: string;
  time_format?: string;
  theme?: string;
  mfa_enabled?: string;
  default_transfer_speed?: string;
  supplier?: string;
  policy_uuids?: string[];
  confirm?: boolean;
  default_policy_to_attach?: GroupDefaultPolicyType;
}

export interface PatchChangeUserPoliciesType {
  policy_changes: {
    user_uuid: string;
    policy_uuids: string[];
  }[];
}

export interface ClientUserFilters {
  app?: VidaAppType | null;
  policy?: string;
  text?: string;
}

export interface ClientUserPermission {
  id: number;
  uuid: string;
  name: string;
  code: string;
  label: string;
}
