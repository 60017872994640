import { AssetViewComponent } from './asset-view-component.enum';
import { FieldConfigId, SelectOption } from '@vdms-hq/shared';
import { Permission } from '../policy';
export enum PlayerMetadataListSource {
  LABEL_DETECTION = 'label-detection',
  FACE_DETECTION = 'face-detection',
  CELEBRITY_RECOGNITION = 'celebrity-recognition',
  VIDEO_SEGMENT_DETECTION = 'video-segment-detection',
  TEXT_DETECTION = 'text-detection',
  CONTENT_MODERATION = 'content-moderation',
  TRANSCRIBE_VIDEO = 'transcribe-video',
  TRANSLATE_SUBTITLES = 'translate-subtitles',
  LOGGING = 'logging',
}

export type AssetViewConfigKey = 'batch_update' | string;

/**
 * @deprecated use directly FieldContainer["id"] instead
 */
export type FieldsContainerRef = FieldContainer['id'];

export enum ConfigLessComponentType {
  AUDIO_METER = 'audio_meter',
  HYBRIK_JOBS = 'hybrik_jobs',
  HYBRIK_RESULTS = 'hybrik_results',
  PREVIEW_TRANSACTIONS = 'preview_transactions',
  STORAGE_LIST_ATTACHMENT = 'storage_list_attachment',
  STORAGE_UPLOAD_ATTACHMENT = 'storage_upload_attachment',
  MI_TRANSCRIBE = 'mi_transcribe',
  MI_SUBTITLES = 'mi_subtitles',
  MI_OBJECT_RECOGNITION = 'mi_object_recognition',
  MI_CELEBRITY_RECOGNITION = 'mi_celebrity_recognition',
  MI_TECHNICAL_CUE_DETECTION = 'mi_technical_cue_detection',
  MI_SHOT_DETECTION = 'mi_shot_detection',
  TRANSCRIPTIONS = 'transcriptions',
  CONTENT_MODERATION = 'content_moderation',
  HOT_COLD = 'hot_cold_logs',
  TIMELINE = 'timeline',
}

export type AllListComponentConfig = {
  type: 'metadata_list';
  place: Container['id'];
  settings: {
    defaultStream: PlayerMetadataListSource[];
    enabledStreams: PlayerMetadataListSource[];
  };
};

export type ConfigLessComponent = {
  type: ConfigLessComponentType;
  place: Container['id'];
};

export interface FieldConfig {
  type: 'field';
  place: Container['id'];
  definition: FieldConfigId;
  override?: FieldConfigOverridable;
}

export const isFormField = (field: Element) => field.type === 'field' && (field as FieldConfig);

export type Element = FieldConfig | AllListComponentConfig | ConfigLessComponent;

export interface FieldConfigOverridable {
  readonly?: boolean;
  label?: string;
  selectOptions?: SelectOption[];
}

/**
 * @deprecated use mainTabs or otherTabs instead
 */
export enum ContainerType {
  after = 'after',
  before = 'before',
  tab = 'tab',
}

export enum NewContainerType {
  main = 'mainTabs',
  other = 'otherTabs',
}

/**
 * @deprecated use Container instead
 */
export interface FieldContainer {
  id: string;
  label: string;
  type: ContainerType;
}

export interface Container {
  id: string;
  label: string;
  type: NewContainerType;
  columns: number;
  permissions?: Permission[];
}

export interface SectionTitle {
  id: string;
  name: string;
}

export interface AssetViewConfig {
  name: string;
  configType: 'batch' | 'default';
  titlePattern?: string;
  components: AssetViewComponent[];
  elements: Element[];
  containers: Container[];
  sectionTitles?: SectionTitle[];
  default?: boolean;
  /**
   * @deprecated - use elements instead
   * todo 5014 replace me with elements
   */
  fields?: FieldConfig[];

  /**
   * @deprecated - use containers instead
   * todo 5014 replace me with containers
   */
  fieldsContainers?: FieldContainer[];
}
