export enum MediaInsightsEndpoints {
  ROOT_OLD = 'media-insights',
  ROOT = 'video-analysis',
  JOBS = 'jobs',
  JOB = 'job',
}

export enum MediaInsightsOptionEndpoints {
  EXECUTE_WORKFLOW = 'execute-workflow',
  WORKFLOW = 'workflow',
  WORKFLOW_VALIDATE = 'workflow/validate',
  METADATA = 'metadata',
  MODELS = 'models',
  TRIGGER = 'trigger',
  JOBS = 'jobs',
  ASSET = 'asset',
  RESULTS = 'results',
  TYPES = 'types',
}
