import { inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import {
  ClientFieldConfigModel,
  ClientModel,
  ColumnSettingsScope,
  ColumnsSettingsScopes,
  ConfigLessComponentType,
  GridSettings,
  isCustomGridField,
  Permission,
} from '@vdms-hq/firebase-contract';
import {
  FieldConfigId,
  FieldDefinitionModel,
  FieldType,
  FilterDefinitionModel,
  FilterType,
  InputDefinitionModel,
  isVisibleInForm,
  isVisibleInTable,
  isVisibleSearch,
  mergeDeep,
  ResourceModel,
  ResultDefinitionModel,
  SelectOption,
  ValueFormat,
} from '@vdms-hq/shared';
import { DataColumn, GridAdvancedMetadata } from '@vdms-hq/ui';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { assetFieldsArray } from './fields/asset-fields';

@Injectable({
  providedIn: 'root',
})
export class FieldsConfigService {
  #activatedClient = inject(ActivatedClientService);
  #translateService = inject(TranslateService);
  #permissionService = inject(PermissionService);

  allFieldDefinitions$: Observable<FieldDefinitionModel[]> = this.#activatedClient.fieldsValueChanges$.pipe(
    map<ClientFieldConfigModel[], FieldDefinitionModel[]>((clientFields) =>
      assetFieldsArray.map((fieldDefinition) => {
        const overriddenConfig = clientFields.find((clientField) => clientField.id === fieldDefinition.id)?.override;

        if (overriddenConfig) {
          return {
            ...fieldDefinition,
            input:
              fieldDefinition.input && overriddenConfig.input
                ? mergeDeep(fieldDefinition.input, {
                    ...overriddenConfig.input,
                  })
                : fieldDefinition.input,
            results: fieldDefinition.results2,
            filters:
              fieldDefinition.filters && overriddenConfig.filters
                ? mergeDeep(fieldDefinition.filters, {
                    ...overriddenConfig.filters,
                  })
                : fieldDefinition.filters,
            label: overriddenConfig.label ?? fieldDefinition.label,
            format: overriddenConfig.format ?? fieldDefinition.format,
            sourceListKey: overriddenConfig.sourceListKey ?? fieldDefinition.sourceListKey,
            isModified: true,
          };
        }
        return fieldDefinition;
      }),
    ),
    switchMap((items) =>
      this.#translateService.get(items.map((item) => item.label)).pipe(
        map((translated) =>
          items.map((item) => ({
            ...item,
            label: translated[item.label],
          })),
        ),
      ),
    ),
    shareReplay(1),
  );

  #fieldsDefinitionWithPermissions$ = this.allFieldDefinitions$.pipe(
    withLatestFrom(this.#activatedClient.permissions$),
    map(([def, permissions]): FieldDefinitionModel[] => {
      return def
        .map((item): FieldDefinitionModel => {
          if (item?.input?.writePermission) {
            item.input.readonly = !this.#permissionService.verifyPermissions(
              item?.input?.writePermission?.permissions as Permission[],
              permissions,
              item.input.writePermission.comparator,
            );
            return item;
          }
          if (item?.input?.readPermission) {
            const hasReadPermission = this.#permissionService.verifyPermissions(
              item.input.readPermission.permissions as Permission[],
              permissions,
              item.input.readPermission.comparator,
            );
            return hasReadPermission ? item : ({} as FieldDefinitionModel);
          }
          return item;
        })
        .filter((item) => !!item.id);
    }),
    shareReplay(1),
  );

  inputDefinitions$ = this.#fieldsDefinitionWithPermissions$.pipe(
    map((defs) => defs.filter((def) => isVisibleInForm(def)) as InputDefinitionModel[]),
  );

  resultsDefinitions$ = this.#fieldsDefinitionWithPermissions$.pipe(
    map((defs) => defs.filter((def) => isVisibleInTable(def)) as ResultDefinitionModel[]),
  );

  filterDefinitions$ = this.#fieldsDefinitionWithPermissions$.pipe(
    map((defs) => defs.filter((def) => isVisibleSearch(def)) as FilterDefinitionModel[]),
  );

  filterAggregationsDefinitions$ = this.filterDefinitions$.pipe(
    map((defs) =>
      defs.filter(
        (defs) =>
          !!defs.filters.aggregationKey &&
          [
            FilterType.SELECTOR,
            FilterType.TEXT_AUTOCOMPLETE,
            FilterType.SELECTOR_GROUP,
            FilterType.CHECKBOX_LIST,
          ].includes(defs.filters.type),
      ),
    ),
  );

  filterAggregationsDefinitionsAsOptions$: Observable<SelectOption[]> = this.filterAggregationsDefinitions$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );

  definitionsForAssetDetailsAdmin$: Observable<SelectOption[]> = this.inputDefinitions$.pipe(
    map((items) => items.filter((item) => item.resource.includes(ResourceModel.ASSET_BROWSE))),
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
    map((items) => {
      const assetComponentsSelectOption = Object.values(ConfigLessComponentType).map((item) => ({
        key: item,
        label: this.#translateService.instant('common.admin.available_components_list.' + item),
      }));
      const metadataList = {
        key: 'metadata_list',
        label: this.#translateService.instant('common.admin.components.player_metadata_list'),
      };

      const filterOut = [
        'mi_transcribe',
        'mi_subtitles',
        'mi_object_recognition',
        'mi_celebrity_recognition',
        'mi_technical_cue_detection',
        'mi_shot_detection',
      ];

      return [
        ...items.filter(({ key }) => !filterOut.includes(key as string)),
        ...assetComponentsSelectOption,
        metadataList,
      ];
    }),
  );

  filterDefinitionsForAssetFilters$: Observable<FilterDefinitionModel[]> = this.filterDefinitions$.pipe(take(1));

  filterDefinitionsForAssetFiltersAdmin$: Observable<SelectOption[]> = this.filterDefinitionsForAssetFilters$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );

  filterDefinitionsForAssetFiltersSettingsModal$: Observable<SelectOption[]> =
    this.filterDefinitionsForAssetFilters$.pipe(
      map((items) =>
        items.map((item) => ({
          key: item.id,
          label: `${item.label}`,
        })),
      ),
    );

  definitionsForLaunchpadDelivery$: Observable<(InputDefinitionModel & GridSettings)[]> = combineLatest([
    this.#activatedClient.clientDefinite$.pipe(
      map((selectedClient: ClientModel) => selectedClient?.launchpad?.deliveryFieldsConfig?.grid ?? []),
    ),
    this.inputDefinitions$,
  ]).pipe(
    map(([fields, definitions]) => {
      return fields
        .map((field) => {
          const validators = 'required' in field && field.required ? [Validators.required] : undefined;

          const defined = definitions.find((def) => def.id === field.key);

          if (defined) {
            return <InputDefinitionModel>{
              ...defined,
              input: {
                ...defined.input,
                validators: validators,
              },
              cols: field.cols,
            };
          }

          const custom = isCustomGridField(field);

          if (custom) {
            return {
              id: field.key,
              resource: [ResourceModel.DELIVERY_PACK],
              label: custom.label ?? 'N/A',
              format: ValueFormat.AS_IS,
              input: {
                type: custom.type ?? FieldType.TEXT,
                validators,
              },
              cols: field.cols,
            };
          }

          return null;
        })
        .filter((item): item is InputDefinitionModel & GridSettings => !!item);
    }),
    shareReplay(1),
  );

  defaultDefinitionForLaunchpadDelivery$ = this.definitionsForLaunchpadDelivery$.pipe(
    withLatestFrom(this.#activatedClient.clientDefinite$),
    map(([definitions, client]) => definitions.find((def) => def.id === client.launchpad?.defaultMetadata)),
  );

  columnDefinitions$: Observable<SelectOption[]> = this.resultsDefinitions$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: item.label,
      })),
    ),
  );

  columnDefinitionsForAssetTable$: Observable<SelectOption[]> = this.columnDefinitions$.pipe(
    switchMap((items) =>
      this.#translateService.get(['common.global.actions', 'common.global.select']).pipe(
        map((translated) => [
          {
            key: 'actions',
            label: translated['common.global.actions'],
          },
          ...items,
          {
            key: 'select',
            label: translated['common.global.select'],
          },
        ]),
      ),
    ),
  );

  columnDefinitionsForAssetGrid$: Observable<SelectOption[]> = this.columnDefinitions$;

  enabledFiltersInSearch$ = (
    configKey: string,
    userFiltersConfig: string[] = [],
  ): Observable<FilterDefinitionModel[]> =>
    this.filterDefinitionsForAssetFilters$.pipe(
      withLatestFrom(this.#activatedClient.searchConfig$, this.#activatedClient.clientFilters$),
      map(([definitions, config, clientFiltersConfig]) => {
        const filtersConfig = userFiltersConfig.length
          ? userFiltersConfig
          : clientFiltersConfig?.[configKey] ?? config?.filters ?? [];
        return filtersConfig.map((id) => definitions.find((item) => item.id === id)).filter((item) => !!item);
      }),
      take(1),
      map(
        (fields) =>
          <FilterDefinitionModel[]>[
            // todo move to config
            {
              id: 'text',
              label: 'Keywords',
              filters: {
                objectPath: 'text',
                validFormat: 'keyword',
                type: FilterType.MASTER_TEXT,
              },
            },
            ...fields,
          ],
      ),
    );

  availableFieldDefinitionsColumnsForMultipleTableViews$ = (scopeName: ColumnSettingsScope) =>
    this.resultsDefinitions$.pipe(
      withLatestFrom(
        this.#activatedClient.clientColumnsValueChanges$,
        this.#activatedClient.columnsConfigValueChanges$,
      ),
      map(([definitions, clientConfig, legacyConfig]) => {
        const config = clientConfig?.[scopeName] ??
          clientConfig?.['default'] ??
          legacyConfig ?? {
            available: [],
            enabled: [],
          };
        return definitions.filter((def) => config.available.includes(def.id)) as ResultDefinitionModel[];
      }),
      shareReplay(1),
    );

  #scopedFields$ = (scopeName: ColumnSettingsScope) =>
    this.availableFieldDefinitionsColumnsForMultipleTableViews$(scopeName).pipe(
      map((definitions) => {
        switch (scopeName) {
          case ColumnsSettingsScopes.LAUNCHPAD_UPLOAD_JOBS:
            return definitions.filter((def) =>
              [ResourceModel.ASSET_BROWSE, ResourceModel.DELIVERY_UPLOAD_JOB].some((r) => def.resource.includes(r)),
            );
          case 'cart':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_CART));
          case 'license_package':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_LICENSED_PACKAGE));
          case 'preview-request':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_PREVIEW_REQUEST));
          case 'shared-packs':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_SHARED_PACK));
          case 'orders':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_ORDER));
          case 'orphan-assets':
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_ORPHAN));
          case 'deleted':
            return definitions.filter(
              (def) =>
                def.resource.includes(ResourceModel.ASSET_BROWSE) || def.resource.includes(ResourceModel.ASSET_DELETED),
            );
          default:
            return definitions.filter((def) => def.resource.includes(ResourceModel.ASSET_BROWSE));
        }
      }),
      shareReplay(1),
    );

  columns2$ = (scopeName: ColumnSettingsScope, hiddenColumns: string[]): Observable<DataColumn[]> =>
    this.#scopedFields$(scopeName).pipe(
      map((definitions) => {
        return definitions.map((def) => ({
          id: def.id,
          label: def.label,
          valuePath: def.results2?.objectPath,
          viewFormat: def.results2?.viewFormat,
          sortable: def?.results2?.sortable ?? false,
          sortObjectPath: def?.results2?.sortObjectPath,
        }));
      }),
      map(
        (columns) =>
          [...columns, { id: 'select', type: 'select' }].filter(
            ({ id }) => !hiddenColumns.includes(id),
          ) as DataColumn[],
      ),
    );

  gridMetadata2$ = (scopeName: ColumnSettingsScope): Observable<GridAdvancedMetadata<unknown>[]> =>
    this.#scopedFields$(scopeName).pipe(
      withLatestFrom(
        this.#activatedClient.clientDefinite$.pipe(
          map((client) => {
            return (
              client.vida?.grid?.metadataV2?.[scopeName] ??
              client.vida?.grid?.metadataV2?.['default'] ??
              client?.vida?.grid?.metadata ??
              []
            );
          }),
        ),
      ),
      map(([definitions, enabledMetadata]) => {
        return enabledMetadata
          .map((key) => {
            const enableDefinition = definitions.find((definition) => definition.id === key);

            if (!enableDefinition) {
              return;
            }
            return {
              label: enableDefinition.label,
              viewFormat: enableDefinition.results2?.viewFormat,
              valuePath: enableDefinition.results2?.objectPath ?? enableDefinition.results2,
            };
          })
          .filter((config) => !!config) as GridAdvancedMetadata<unknown>[];
      }),
    );

  overrideFieldDefinition(id: FieldConfigId, value?: Partial<FieldDefinitionModel>) {
    return this.#activatedClient.fieldsValueChanges$.pipe(
      take(1),
      switchMap((fields) => {
        const nextFields: ClientFieldConfigModel[] = fields.filter((field) => field.id !== id);
        if (value) {
          nextFields.push({
            id,
            override: value,
          });
        }
        return this.#activatedClient.update({ fields: nextFields });
      }),
    );
  }
}
