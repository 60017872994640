import { Observable, BehaviorSubject } from 'rxjs';
import { PageableDataSource } from '../contracts/pageable.ds';
import { PageEvent } from '../../models/pagination/pagination.model';

export function Pageable<T extends new (...args: any[]) => object>(Base: T) {
  return class extends Base implements PageableDataSource {
    #pageIndex$ = new BehaviorSubject(0);
    #pageSize$ = new BehaviorSubject(12);
    pageIndex$ = this.#pageIndex$.asObservable();
    pageSize$ = this.#pageSize$.asObservable();
    pageSizeOptions: number[] = [12, 24, 48, 96, 192];
    pageSizeOptions$?: Observable<number[]>;
    total$: Observable<number> = new BehaviorSubject(0);
    changePerPageSize$?: BehaviorSubject<number>;
    pagination$?: BehaviorSubject<{ page: string; perPage: string } | null>;

    constructor(...args: any[]) {
      super(...args);
      this.pageChange = this.pageChange.bind(this);
    }

    pageChange($event: PageEvent) {
      this.#pageIndex$.next($event.pageIndex);
      this.#pageSize$.next($event.pageSize);
    }
  };
}
