import { inject, Injectable } from '@angular/core';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import {
  CollectionAccessTypes,
  CollectionModelFlat,
  CollectionsService,
  PaginationAPIModel as Pagination,
  PaginationAPIProps as PaginationProps,
} from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { DiacriticsConverter, RefreshService } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { SORT_BY } from '../utils/collections-filters-provider';
import {
  BehaviorSubject,
  debounceTime,
  Observable,
  of,
  startWith,
  switchMap,
  map,
  combineLatest,
  EMPTY,
  catchError,
  shareReplay,
} from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { StorageUrlService } from '@vdms-hq/storage';

export type CollectionsFiltersView = {
  collectionType?: string;
  sort: Pick<PaginationProps, 'orderBy' | 'orderDir'>;
  nameSubject?: string;
  filter?: string;
};

@Injectable({ providedIn: 'root' })
export class CollectionsMultiDs extends RouterParamsPagination implements MultipleViewDataSource<CollectionModelFlat> {
  private authService = inject(AuthService);
  private toastService = inject(ToastService);
  private refreshService = inject(RefreshService);
  private collectionsService = inject(CollectionsService);
  private storageUrlService = inject(StorageUrlService);

  total$ = new BehaviorSubject(0);
  emptyResults$ = new BehaviorSubject(this.total$.value === 0);
  isLoading$ = new BehaviorSubject(false);
  refresh$ = new BehaviorSubject(true);

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
    sort: new FormControl<string>(SORT_BY[0].key),
    collectionType: new FormControl<string>('owned'),
  });

  // todo [archer] duplicated code
  values$: Observable<CollectionsFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const selectedSort = SORT_BY.find((item) => item.key === formValue?.sort)?.props;
      const filters = <CollectionsFiltersView>{};

      if (selectedSort) {
        filters.sort = selectedSort;
      }

      switch (formValue?.collectionType) {
        case 'owned':
          filters.collectionType = 'owned';
          break;
        case 'shared':
          filters.collectionType = 'shared';
          break;
        default:
          filters.collectionType = 'all';
          break;
      }

      if (formValue?.keyword) {
        filters.filter = DiacriticsConverter(formValue.keyword);
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );
  // todo [archer] fix returned type
  allData$: Observable<CollectionModelFlat[]> = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.values$,
    this.authService.authDefinite$,
    this.refresh$,
    this.refreshService.refresh$,
  ]).pipe(
    tap(() => this.isLoading$.next(true)),
    switchMap(([pageIndex, pageSize, filters, user]) => {
      const pagination = {
        page: pageIndex,
        perPage: pageSize,
      };
      return this.collectionsService
        .getList(
          {
            filter: filters.filter,
          },
          Pagination.create({ ...pagination, ...filters.sort }),
          filters.collectionType,
        )
        .pipe(
          catchError((error) => this.#errorHandler(error)),
          tap((response) => {
            this.total$.next(response.total);
            this.emptyResults$.next(response.total === 0);
          }),
          switchMap((collections) => {
            const collectionUuids = collections.data.map(({ uuid }) => uuid);
            const accessTypes$ =
              this.filters.value.collectionType !== 'owned'
                ? this.collectionsService.getCollectionAccessType(collectionUuids)
                : of(collectionUuids.map((uuid) => ({ uuid, access_type: 'owned' })) as CollectionAccessTypes[]);
            return combineLatest([of(collections), accessTypes$]);
          }),
          map(([response, accessTypes]) => {
            return response.data.map((collection) => {
              const accessType = accessTypes.find(({ uuid }) => collection.uuid === uuid)?.access_type ?? 'owned';
              return {
                ...collection,
                access_type: accessType,
                custom_cover_path: this.storageUrlService.updateCdn(collection.custom_cover_path),
              };
            });
          }),
        );
    }),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );
  connection$: Observable<CollectionModelFlat[]> = this.allData$;

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'collections', message: 'Error fetching collections' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }

  refresh() {
    this.refresh$.next(true);
  }
}
