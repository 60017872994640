import { Injectable } from '@angular/core';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  BatchDeleteNotificationSubscription,
  BatchPostNotificationSubscription,
  Notification,
  NOTIFICATIONS_CATEGORIES,
  NotificationSubscription,
  NotificationTypes,
  PatchNotificationSubscriptionInterface,
  PostNotificationSubscription,
} from './notification-subscriptions.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { HttpHeaders } from '@angular/common/http';
import { generateNotificationParams } from './notifications';
import { PaginationAPIModel as Pagination, PaginationAPIProps as PaginationProps } from '../pagination.model';

enum NOTIFICATION_ENDPOINTS {
  SUBSCRIPTION = 'notification-subscription',
  SUBSCRIPTIONS = 'notification-subscriptions',
  NOTIFICATION = 'notification',
  NOTIFICATIONS = 'notifications',
}

@Injectable({ providedIn: 'root' })
export class NotificationSubscriptionsService {
  constructor(private readonly apiService: ApiService) {}

  getNotificationTypes(): Observable<NotificationTypes[]> {
    return this.apiService
      .get<ApiPaginatedResponse<NotificationTypes>>(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTION}/types`)
      .pipe(GetResponseData);
  }

  deleteSubscription(uuid: string): Observable<void> {
    return this.apiService.delete(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTION}/${uuid}`);
  }

  updateSubscription(
    uuid: string,
    payload: PatchNotificationSubscriptionInterface,
  ): Observable<ApiResponse<NotificationSubscription>> {
    return this.apiService.patch(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTION}/${uuid}`, payload);
  }

  getUserSubscriptions() {
    return this.apiService.get<ApiPaginatedResponse<NotificationSubscription>>(
      `${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}`,
    );
  }

  getAllSubscriptions(pagination: {
    pageSize: number;
    pageIndex: number;
    text: string;
  }): Observable<ApiPaginatedResponse<NotificationSubscription>> {
    const headers = new HttpHeaders()
      .set('per-page', String(pagination.pageSize))
      .set('page', String(pagination.pageIndex))
      .set('text', pagination.text.trim());
    return this.apiService.get<ApiPaginatedResponse<NotificationSubscription>>(
      `${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}/all`,
      { headers },
    );
  }

  getSubscription(uuid: string): Observable<NotificationSubscription> {
    return this.apiService
      .get<ApiResponse<NotificationSubscription>>(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTION}/${uuid}`)
      .pipe(GetResponseData);
  }

  getSubscriptionsByType(
    type: NotificationTypes,
    pagination: {
      pageSize: number;
      pageIndex: number;
      text: string;
    },
  ): Observable<ApiPaginatedResponse<NotificationSubscription>> {
    const headers = new Pagination({
      page: pagination.pageIndex,
      perPage: pagination.pageSize,
    }).applyToHeaders(new HttpHeaders().set('text', pagination.text.trim()));
    return this.apiService.get(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}/${type}/subscriptions`, { headers });
  }

  createSubscription(payload: PostNotificationSubscription): Observable<ApiResponse<NotificationSubscription>> {
    return this.apiService.post(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTION}`, payload);
  }

  createMultipleSubscriptions(payload: BatchPostNotificationSubscription[]) {
    return this.apiService.post(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}/batch`, {
      subscriptionsData: payload,
    });
  }

  updateMultipleSubscriptions(payload: BatchPostNotificationSubscription[]) {
    return this.apiService.patch(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}/batch`, {
      subscriptionsData: payload,
    });
  }

  deleteMultipleSubscriptions(payload: Array<BatchDeleteNotificationSubscription>) {
    return this.apiService.delete(`${NOTIFICATION_ENDPOINTS.SUBSCRIPTIONS}/batch`, { subscriptionsData: payload });
  }
  getNotifications(
    paramItems: { read?: boolean; types?: string; categories?: NOTIFICATIONS_CATEGORIES },
    pagination: PaginationProps,
  ): Observable<ApiPaginatedResponse<Notification>> {
    const headers = new Pagination(pagination).applyToHeaders(new HttpHeaders());
    const params = generateNotificationParams(paramItems);
    return this.apiService.get(`${NOTIFICATION_ENDPOINTS.NOTIFICATIONS}`, { params, headers });
  }

  markNotificationAsRead(uuid: string) {
    return this.apiService.patch(`${NOTIFICATION_ENDPOINTS.NOTIFICATION}/mark-as-read/${uuid}`, {});
  }

  markAllNotificationsAsRead() {
    return this.apiService.patch(`${NOTIFICATION_ENDPOINTS.NOTIFICATIONS}/mark-as-read`, {});
  }

  triggerNotifications(uuid: string, payload: { assetUuids: string[]; emails: string[] }) {
    return this.apiService.post(`licensed-package/${uuid}/trigger-notification`, payload);
  }
}
