<ng-container *ngIf="!passwordLessSent && !error; else checkEmail">
  <form [formGroup]="form" class="auth-form" (submit)="sendEmail()">
    <span class="auth-form__header" [sharedE2eId]="['login', 'header']">{{ headerTranslateKey | translate }}</span>

    <div class="auth-form__inputs">
      <vdms-hq-ui-form-input-email
        formControlName="email"
        [label]="'common.auth.emailLabel' | translate"
        [placeholder]="'common.auth.emailPlaceholder' | translate"
      >
      </vdms-hq-ui-form-input-email>
    </div>

    <div class="auth-form__pre-footer" *ngIf="isBBC">
      <div class="login-trouble-line">
        <span>{{ 'common.auth.loginTrouble_bbc' | translate }}</span>
        <a href="mailto:support@vida.studio">{{ 'common.auth.loginTroubleContact' | translate }}</a>
      </div>
      <div class="login-trouble-line">
        <span>{{ 'common.auth.loginTrouble_bbc2' | translate }}</span>
      </div>
    </div>

    <div class="auth-form__footer">
      <div *ngIf="!isBBC" class="login-trouble-line">
        <span>{{ 'common.auth.loginTrouble' | translate }}</span>
        <a href="mailto:support@vida.studio">{{ 'common.auth.loginTroubleContact' | translate }}</a>
      </div>
      <vdms-hq-ui-button [isSubmit]="true" color="primary" [loading]="loading" [disabled]="form.invalid">{{
        loginButtonTranslateKey | translate
      }}</vdms-hq-ui-button>
    </div>
  </form>
</ng-container>

<ng-template #checkEmail>
  <h2>{{ (error ? 'common.auth.badEmail' : 'common.auth.checkEmail') | translate }}</h2>
  <button class="login-reset-form-button" mat-raised-button (click)="reset()">
    {{ 'common.auth.resetForm' | translate }}
  </button>
</ng-template>
