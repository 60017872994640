import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeepPartial } from '@vdms-hq/shared';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiEmptyResponse, ApiPaginatedResponse, ApiResponse } from '../api.model';
import { ApiService } from '../api.service';
import { FlatAsset } from '../order/ordered-asset.model';
import { Pagination } from '../parameters/pagination';
import { AsperaFiles } from '../upload-job/upload-job.model';
import {
  AssetAsperaPost,
  AssetAsperaPostResponse,
  ASSETS_ENDPOINTS,
  DOWNLOAD_TYPE,
} from './models/asset-endpoints.model';
import {
  Asset,
  AssetLink,
  AssetMapFilters,
  AssetPatch,
  CreateAssetVirtualParams,
  DownloadProxyExtra,
  HotColdChangeLogData,
  HotColdEstimateResponse,
  HotColdEstimateSearch,
  HotColdEstimateSimple,
  SetAsColdOnlyType,
  SubtitleResponse,
} from './models/asset.model';
import { AssetSearchFilterParam, AssetSearchFilters } from './models/search/filters-v2.model';
import { AssetImportResponse } from './models/asset-import-response';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService {
  constructor(private apiService: ApiService) {}

  get = (id: string) => this.apiService.get<ApiResponse<Asset>>(`${ASSETS_ENDPOINTS.ONE}/${id}`).pipe(GetResponseData);

  getLatest = (outerHeaders?: HttpHeaders) => {
    const headers = outerHeaders ? outerHeaders : new HttpHeaders();
    return this.apiService
      .get<ApiPaginatedResponse<FlatAsset>>(`${ASSETS_ENDPOINTS.LIST}/latest`, { headers })
      .pipe(GetResponsePaginationData);
  };

  createAsperaAssets = (data: AssetAsperaPost) =>
    this.apiService.post<AssetAsperaPost, AssetAsperaPostResponse>('asset/aspera', data).pipe(map(({ data }) => data));

  placeholderAssetUpload = (uuid: string, data: AsperaFiles) =>
    this.apiService
      .post<AsperaFiles, AssetAsperaPostResponse>(`asset/${uuid}/aspera`, data)
      .pipe(map(({ data }) => data));

  uploadAssetToVimeo(id: string) {
    return this.apiService.get(`${ASSETS_ENDPOINTS.ONE}/${id}/vimeo-upload`);
  }

  postVirtualAsset(json: CreateAssetVirtualParams, id: string) {
    return this.apiService
      .post<CreateAssetVirtualParams, ApiResponse<string>>(`${ASSETS_ENDPOINTS.ONE}/${id}/virtual`, json)
      .pipe(GetResponseData);
  }

  regenerateProxy(id: string) {
    return this.apiService.patch(`${ASSETS_ENDPOINTS.ONE}/${id}/regenerate-proxy`, {});
  }

  updateAsset(json: AssetPatch, id: string) {
    const request: AssetPatch = JSON.parse(JSON.stringify(json));

    // todo https://github.com/vdms-hq/vida-frontend/issues/2491
    const startOfDay = (date: string) =>
      moment.utc(moment(date).toISOString(true).substr(0, 10)).startOf('day').toISOString();

    if (request.ext_title_info) {
      const {
        ext_title_info: { tx_date },
      } = request;

      request.ext_title_info.tx_date = tx_date ? startOfDay(tx_date) : tx_date;
    }

    return this.apiService
      .patch<AssetPatch, ApiResponse<Asset>>(`${ASSETS_ENDPOINTS.ONE}/${id}`, request)
      .pipe(GetResponseData);
  }

  getAssetSpecPdf(id: string): Observable<AssetLink> {
    return this.apiService
      .get<ApiResponse<AssetLink>>(`${ASSETS_ENDPOINTS.ONE}/${id}/${DOWNLOAD_TYPE.SPEC_PDF_FILE}`)
      .pipe(GetResponseData);
  }

  getAssetDownloadLink(id: string): Observable<AssetLink> {
    return this.apiService
      .get<ApiResponse<AssetLink>>(`${ASSETS_ENDPOINTS.ONE}/${id}/${DOWNLOAD_TYPE.FILE}`)
      .pipe(GetResponseData);
  }

  getAssetDownloadProxyLink(id: string, body: DownloadProxyExtra): Observable<AssetLink> {
    return this.apiService
      .post<DownloadProxyExtra | undefined, ApiResponse<AssetLink>>(
        `${ASSETS_ENDPOINTS.ONE}/${id}/${DOWNLOAD_TYPE.PROXY_FILE}`,
        body,
      )
      .pipe(GetResponseData);
  }

  getAssetSubtitles(id: string): Observable<SubtitleResponse> {
    return this.apiService
      .get<ApiResponse<SubtitleResponse>>(`${ASSETS_ENDPOINTS.ONE}/${id}/download-subtitles`)
      .pipe(GetResponseData);
  }

  generateMediaPulseJson(clientId?: string): Observable<ApiEmptyResponse> {
    return this.apiService.post<undefined, ApiEmptyResponse>(
      `${ASSETS_ENDPOINTS.LIST}/generate-mediapulse-json`,
      undefined,
      clientId ? new HttpHeaders({ 'vida-client-id': clientId }) : undefined,
    );
  }

  batchUpdate = (assets: string[], payload: Partial<Asset>) => {
    return this.apiService.patch<{ assets: string[]; payload: Partial<Asset> }, ApiEmptyResponse>(
      `${ASSETS_ENDPOINTS.LIST}/batch`,
      {
        assets,
        payload,
      },
    );
  };

  deleteAssets = (body: { deleteReason: string; deletionBillable: boolean; assetUuids: string[] }) => {
    return this.apiService.delete<
      ApiEmptyResponse,
      { deleteReason: string; deletionBillable: boolean; assetUuids: string[] }
    >(`${ASSETS_ENDPOINTS.LIST}`, body, undefined);
  };

  setAssetsAsCold(assetIds: string[]) {
    return this.apiService.post<SetAsColdOnlyType, ApiEmptyResponse>(`${ASSETS_ENDPOINTS.SET_AS_COLD}`, {
      assets: assetIds,
    });
  }

  setQuarantine(assets: string[], is_quarantined: boolean) {
    const payload: DeepPartial<Asset> = { general: { is_quarantined } };

    return of(assets.length > 1).pipe(
      switchMap((isBatch) => {
        if (isBatch) {
          return this.batchUpdate(assets, payload as Partial<Asset>);
        }

        return this.updateAsset(payload, assets[0]);
      }),
    );
  }

  setFilteredAssetsAsCold(filters: AssetSearchFilterParam) {
    return this.apiService.post<AssetSearchFilterParam, ApiEmptyResponse>(
      `${ASSETS_ENDPOINTS.SET_AS_COLD_SEARCH}`,
      filters,
    );
  }

  getAssetsMoveToColdEstimation(assetIds: string[], searchParams?: AssetSearchFilterParam) {
    if (!searchParams) {
      return this.apiService
        .post<HotColdEstimateSimple, ApiResponse<HotColdEstimateResponse>>(`${ASSETS_ENDPOINTS.SET_AS_HOT_PRICE}`, {
          assets: assetIds,
        })
        .pipe(GetResponseData);
    } else {
      return this.apiService
        .post<HotColdEstimateSearch, ApiResponse<HotColdEstimateResponse>>(
          `${ASSETS_ENDPOINTS.SET_AS_HOT_SEARCH_PRICE}`,
          { filters: searchParams.filters, text: searchParams.text },
        )
        .pipe(GetResponseData);
    }
  }

  getAssetStorageChangeLogs(assetUuid: string, pagination?: Pagination) {
    const headers = new HttpHeaders({
      ...pagination?.toHeaders(),
    });
    return this.apiService
      .get<ApiResponse<HotColdChangeLogData[]>>(`${ASSETS_ENDPOINTS.ONE}/${assetUuid}/${ASSETS_ENDPOINTS.LOGS}`, {
        headers,
      })
      .pipe(GetResponseData);
  }

  changePlaceholderToAwaitingAnalyseStatus(batchId: string, isReuploaded: boolean) {
    return this.apiService.patch(`${ASSETS_ENDPOINTS.ONE}/aspera/complete`, {
      batch_id: batchId,
      is_reuploaded: isReuploaded,
    });
  }

  getAssetsByCoords(filters: AssetMapFilters) {
    return this.apiService
      .post<AssetMapFilters, ApiPaginatedResponse<FlatAsset>>(`${ASSETS_ENDPOINTS.LIST}/map`, filters)
      .pipe(GetResponsePaginationData);
  }

  updateAssetFromImport(filename: string): Observable<AssetImportResponse> {
    return this.apiService.patch(ASSETS_ENDPOINTS.LIST, { filename });
  }
}
