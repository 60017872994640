import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpHeaders } from '@angular/common/http';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import {
  ContractCreate,
  ContractGet,
  ContractListItem,
  ContractUpdate,
  RIGHTS_CONTRACTS_ENDPOINTS,
  RightsContractsFiltersView,
} from './rights-contracts.model';
import { PaginationAPIModel as Pagination } from '../pagination.model';

@Injectable({ providedIn: 'root' })
export class RightsContractsService {
  constructor(private readonly apiService: ApiService) {}

  getMany = (pagination?: Pagination, filters?: RightsContractsFiltersView) => {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    if (filters) {
      headers = filters.keyword ? headers.append('filter', filters.keyword) : headers;
      headers = filters.partners ? headers.append('partner-id', filters.partners) : headers;
    }
    headers = headers.append('Cache-Control', 'max-age=0');

    return this.apiService
      .get<ApiPaginatedResponse<ContractListItem>>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT_LIST}`, { headers })
      .pipe(GetResponsePaginationData);
  };

  getOne = (uuid: string) => {
    return this.apiService
      .get<ApiResponse<ContractGet>>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT}/${uuid}`)
      .pipe(GetResponseData);
  };

  create = (contract: ContractCreate) => {
    return this.apiService
      .post<ContractCreate, ApiResponse<ContractGet>>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT}`, contract)
      .pipe(GetResponseData);
  };

  patch = (uuid: string, contract: ContractUpdate) => {
    return this.apiService
      .patch<ContractUpdate, ApiResponse<ContractGet>>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT}/${uuid}`, contract)
      .pipe(GetResponseData);
  };

  remove = (uuid: string) => {
    return this.apiService.delete<null, undefined>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT}/${uuid}`);
  };

  setReadyToRelease = (uuid: string) => {
    return this.apiService
      .put<any, ApiResponse<ContractGet>>(`${RIGHTS_CONTRACTS_ENDPOINTS.ROOT}/${uuid}/set-ready-to-release`, {})
      .pipe(GetResponseData);
  };
}
