import { Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  CartApiService,
  DestinationApiService,
  DestinationConfigModel,
  DestinationModel,
  ORDER_TYPE,
} from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import { DateValidator, DestroyComponent, emailsArrayValidator, SelectOption } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { InfoBarClickAction, InfoBarType } from '@vdms-hq/ui';
import { CartStateService } from '@vdms-hq/cart-core';
import {
  CheckoutValidationErrorsDialogComponent,
  validationErrorsInput,
  validationErrorsOutput,
} from '../checkout-delivery-validation-errors-dialog/checkout-validation-errors-dialog.component';
import { ddCartErrorsChecks } from '../../logic/dd-cart-errors-checks';
import { DeliveryDestinationsValidators } from '@vdms-hq/delivery-destinations';

const burnInTextValidator = DeliveryDestinationsValidators.burnInTextValidator;

export interface SubmitOrderDialogOutput {
  destinations: { uuid: string; configUuid: string; hasBurnInText: boolean; burnInTextValue: string | null }[];
  deliveryEmails: string[];
  notificationEmails: string[];
  packageTitle: string;
  purchaseOrderNo: string;
  subject: string;
  deliveryDate: string | null;
  expiresAt: string | null;
  comment: string;
}

export interface ValidationErrorList {
  assetOriginalFilename?: string;
  cartItemUuids?: string[];
  type: 'error' | 'warning';
  message: string;
  assetUuid?: string;
}

@Component({
  selector: 'vdms-hq-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss'],
})
export class OrderDialogComponent extends DestroyComponent implements OnInit, OnDestroy {
  private dialogRef = inject(MatDialogRef<OrderDialogComponent>);
  private dialog = inject(MatDialog);
  private cartStateService = inject(CartStateService);
  private cartApiService = inject(CartApiService);
  private toast = inject(ToastService);
  private authService = inject(AuthService);
  private destinationsService = inject(DestinationApiService);
  private activatedClientService = inject(ActivatedClientService);
  public translate = inject(TranslateService);

  @ViewChild('errorList') errorList!: ElementRef;
  protected readonly InfoBarType = InfoBarType;
  protected readonly InfoBarClickAction = InfoBarClickAction;

  cartNonAssetCount$ = of(0);
  loading = true;
  orderForm: FormGroup = new FormGroup({});
  submitted = false;
  deliveryMinDate = new Date().toISOString().split('T')[0];
  expiryMinDate = moment().add(1, 'd').toISOString().split('T')[0];
  expiryMaxDate = moment().add(90, 'd').toISOString().split('T')[0];
  defaultExpiryDate = moment().add(7, 'd').toISOString();
  deliveryMethodOptions: SelectOption[] = [
    {
      key: 'manual',
      label: this.translate.instant('pages.order.email'),
    },
    {
      key: 'predefined',
      label: this.translate.instant('pages.order.destination'),
    },
  ];

  deliveryTimeSelectOptions$: Observable<SelectOption[]> = this.activatedClientService.integrations$.pipe(
    filter((value) => !value.salesforce?.enabled),
    map(() => [
      {
        key: 'immediate',
        label: this.translate.instant('pages.order.deliver_now'),
      },
      {
        key: 'delayed',
        label: this.translate.instant('pages.order.select_date'),
      },
    ]),
  );
  expiryTimeOptions: SelectOption[] = [
    {
      key: '7',
      label: this.translate.instant('pages.order.expiry_one_week'),
    },
    {
      key: '14',
      label: this.translate.instant('pages.order.expiry_two_weeks'),
    },
    {
      key: '30',
      label: this.translate.instant('pages.order.expiry_one_month'),
    },
    {
      key: 'custom',
      label: this.translate.instant('pages.order.expiry_custom'),
    },
  ];
  deliveryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  expiryTimeOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject<SelectOption[]>([]);
  isValidated$ = new BehaviorSubject(false);
  emailsPlaceholder = this.translate.instant('pages.order.email_placeholder');
  validationErrorList$ = new BehaviorSubject<ValidationErrorList[] | null>(null);
  validationErrorAssetUuids$ = new BehaviorSubject<string[]>([]);
  emailOptions: SelectOption[] = [];
  loading$ = new BehaviorSubject(false);
  #allDeliveriesConfig$ = new BehaviorSubject<DestinationConfigModel[]>([]);

  #allDeliveries$ = new BehaviorSubject<DestinationModel[]>([]);

  private get genericTimeOptions() {
    const timeOptions: SelectOption[] = [];
    const date = moment().utc();

    for (let h = 0; h < 24; ++h) {
      for (let m = 0; m < 60; m = m + 15) {
        date.hours(h);
        date.minutes(m);

        const key = date.format('hh:mm A');

        timeOptions.push({
          key,
          label: key,
        });
      }
    }

    return timeOptions;
  }

  get unavailableOptions(): string[] {
    return this.destinations.value.map((data) => (data?.configUuid ?? '') as string) || [];
  }

  get emailsCtrl() {
    const ctrl = this.orderForm.get('deliveryEmails');

    if (ctrl) {
      return ctrl as FormControl<string[]>;
    }
    return null;
  }

  get notificationEmailsCtrl() {
    const ctrl = this.orderForm.get('notificationEmails');

    if (ctrl) {
      return ctrl as FormControl<string[]>;
    }
    return null;
  }

  get deliveryDate() {
    return this.orderForm.get('deliveryDate') as FormControl<string>;
  }

  get deliveryDelay() {
    return this.orderForm.get('deliveryDelay') as FormControl<string>;
  }

  get deliveryMethod() {
    return this.orderForm.get('deliveryMethod') as FormControl<string>;
  }

  get expiry() {
    return this.orderForm.get('expiry') as FormControl<string>;
  }

  get expiryDate() {
    return this.orderForm.get('expiryDate') as FormControl<string>;
  }

  get destinations() {
    return this.orderForm.get('destinations') as FormArray<
      FormGroup<{
        configUuid: FormControl<string | null>;
        uuid: FormControl<string | null>;
        hasBurnInText: FormControl<boolean>;
        burnInTextValue: FormControl<string | null>;
      }>
    >;
  }

  get destinationsControls() {
    return this.destinations.controls as FormGroup<{
      configUuid: FormControl<string | null>;
      uuid: FormControl<string | null>;
      hasBurnInText: FormControl<boolean>;
      burnInTextValue: FormControl<string | null>;
    }>[];
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    {
      this.deliveryTimeOptions$.complete();
      this.expiryTimeOptions$.complete();
    }
  }

  ngOnInit(): void {
    this.authService.auth$.pipe(take(1)).subscribe(() => {
      this.orderForm = new FormGroup({
        destinations: new FormArray([
          new FormGroup({
            uuid: new FormControl<string | null>(null, Validators.required),
            configUuid: new FormControl<string | null>(null, Validators.required),
            hasBurnInText: new FormControl<boolean>(false, { nonNullable: true }),
            burnInTextValue: new FormControl<string | null>(null, [burnInTextValidator()]),
          }),
        ]),
        deliveryEmails: new FormControl<string[]>([], [Validators.required, emailsArrayValidator(true)]),
        notificationEmails: new FormControl<string[]>([], [emailsArrayValidator(true)]),
        packageTitle: new FormControl<string>('', Validators.required),
        purchaseOrder: new FormControl<string>('', Validators.required),
        subject: new FormControl<string>('', Validators.required),
        deliveryDelay: new FormControl<string>('immediate'),
        deliveryDate: new FormControl<string>(moment().add(15, 'm').toISOString()),
        deliveryMethod: new FormControl<string>('manual'),
        expiry: new FormControl<string>('7'),
        expiryDate: new FormControl<string>(this.defaultExpiryDate),
        comment: new FormControl<string>(''),
      });

      this.#cacheDeliveryDestinations();

      this.orderForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
        this.isValidated$.next(false);
      });
      this.deliveryMethod.valueChanges.pipe(this.takeUntilDestroyed(), startWith('manual')).subscribe((method) => {
        this.emailsCtrl?.reset();
        this.notificationEmailsCtrl?.reset();
        this.destinations.clear();
        this.addDestination();

        if (method === 'predefined') {
          this.expiry.setValue('30');
          this.expiry.disable();
          this.destinations.enable();
        }

        if (method === 'manual') {
          this.expiry.setValue('7');
          this.expiry.enable();
          this.destinations.disable();
        }
      });
      this.loading = false;
    });

    this.expiryTimeOptions$.next(this.genericTimeOptions);
    this.updateDeliveryTimeOptions();

    this.deliveryDelay?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: (shipping) => {
        const validators = [Validators.required, DateValidator.dateNotPast()];

        if (shipping === 'delayed') {
          this.deliveryDate?.addValidators(validators);
        } else if (shipping === 'immediate') {
          this.deliveryDate?.removeValidators(validators);
        }

        this.orderForm.updateValueAndValidity();
      },
    });

    this.expiry?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: (expiry) => {
        switch (expiry) {
          case '14':
            this.expiryDate?.setValue(moment().add(14, 'd').toISOString());
            break;
          case '30':
            this.expiryDate?.setValue(moment().add(30, 'd').toISOString());
            break;
          case 'custom':
            this.expiryDate?.setValue(moment().add(7, 'd').toISOString());
            break;
          default:
            this.expiryDate?.setValue(moment().add(7, 'd').toISOString());
        }
      },
    });

    this.emailsCtrl?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: (value) => {
        if (value.length) {
          this.emailsPlaceholder = null;
        } else {
          this.emailsPlaceholder = this.translate.instant('pages.order.email_placeholder');
        }
      },
    });

    this.deliveryDate?.valueChanges.pipe(this.takeUntilDestroyed()).subscribe({
      next: () => {
        if (this.deliveryDelay?.value == 'delayed') {
          this.updateDeliveryTimeOptions();
        }
      },
    });

    this.cartApiService
      .getRecentEmails()
      .pipe(
        catchError(() => of([])),
        map((emails) => emails?.map((res: string) => ({ key: res, label: res }))),
        tap((resp) => (this.emailOptions = resp)),
        this.takeUntilDestroyed(),
      )
      .subscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addDestination() {
    this.destinations.push(
      new FormGroup<{
        configUuid: FormControl<string | null>;
        uuid: FormControl<string | null>;
        hasBurnInText: FormControl<boolean>;
        burnInTextValue: FormControl<string | null>;
      }>({
        uuid: new FormControl<string | null>(null, Validators.required),
        configUuid: new FormControl<string | null>(null, Validators.required),
        hasBurnInText: new FormControl<boolean>(false, { nonNullable: true }),
        burnInTextValue: new FormControl<string | null>(null, [burnInTextValidator()]),
      }),
    );
  }

  removeDestination(index: number) {
    this.destinations.removeAt(index);
    this.completeEmailControls();
  }

  validate() {
    if (this.orderForm.invalid) {
      return;
    }
    const delivery_config_uuids: string[] = this.destinations.value
      .map(({ configUuid }) => configUuid)
      .filter(Boolean) as string[];
    if (!delivery_config_uuids?.length) {
      return;
    }
    this.validationErrorList$.next(null);
    this.validationErrorAssetUuids$.next([]);
    this.loading$.next(true);

    const payload = {
      order_type: ORDER_TYPE.DELIVERY_DESTINATION,
      order_data: { delivery_config_uuids },
    };

    combineLatest([
      this.cartApiService.validate(payload).pipe(
        catchError((error) => {
          this.toast.error({ id: 'error', message: error.error.data });
          this.validationErrorList$.next([]);
          this.validationErrorAssetUuids$.next([]);
          this.loading$.next(false);
          if (error.error.data === 'Cart is empty') {
            this.dialogRef.close();
          }
          return throwError(error.message);
        }),
      ),
      this.#allDeliveriesConfig$,
    ])
      .pipe(
        take(1),
        tap(() => this.loading$.next(false)),
      )
      .subscribe(([validationResult, configs]) => {
        if (!validationResult?.length) {
          this.toast.success({ id: 'validation_success', message: 'Validation success.' });
          this.isValidated$.next(true);
          this.loading$.next(false);
          return;
        }

        const errorChecks = ddCartErrorsChecks(validationResult, configs);
        const { validationErrorList } = errorChecks;
        const { errorUuids } = errorChecks;

        this.validationErrorAssetUuids$.next(errorUuids);
        this.validationErrorList$.next(validationErrorList);

        this.isValidated$.next(true);
        this.loading$.next(false);
        if (errorUuids.length > 0) {
          setTimeout(() => this.errorList.nativeElement.scrollIntoView({ behavior: 'smooth' }));
        }
      });
  }

  openFailedDeliveryDestinationValidationDialog() {
    const errorUuids = this.validationErrorAssetUuids$.value;
    const errorList = this.validationErrorList$.value;
    if (!errorUuids?.length && !errorList?.length) {
      return;
    }

    const errorDialog = this.dialog.open<
      CheckoutValidationErrorsDialogComponent,
      validationErrorsInput,
      validationErrorsOutput
    >(CheckoutValidationErrorsDialogComponent, { data: { errors: errorList as ValidationErrorList[] } });

    errorDialog
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (!result?.confirmed) {
            return EMPTY;
          } else {
            return this.cartApiService.delete(errorUuids);
          }
        }),
        take(1),
      )
      .subscribe({
        next: () => {
          this.cartStateService.refresh$.next(0);
          this.toast.success({ id: 'remove_assets_from_cart', message: 'Assets removed successfully.' });
          this.validate();
        },
        error: () => {
          this.toast.error({ id: 'remove_assets_from_cart', message: 'Assets remove failed.' });
        },
      });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.orderForm.invalid) {
      console.warn('Form contains invalid values.');
      return;
    }

    const destinations = this.destinations.value
      ?.map(({ configUuid, uuid, hasBurnInText, burnInTextValue }) => ({
        configUuid,
        uuid,
        hasBurnInText,
        burnInTextValue: hasBurnInText ? burnInTextValue : null,
      }))
      ?.filter(({ uuid, configUuid }) => uuid && configUuid);

    const data = {
      destinations: destinations?.length && this.destinations.enabled ? destinations : undefined,
      deliveryEmails: this.emailsCtrl?.enabled ? this.emailsCtrl?.value : undefined,
      notificationEmails: this.notificationEmailsCtrl?.enabled ? this.notificationEmailsCtrl?.value : undefined,
      packageTitle: this.orderForm.controls['packageTitle'].value,
      purchaseOrderNo: this.orderForm.controls['purchaseOrder'].value,
      subject: this.orderForm.controls['subject'].value,
      deliveryDate: this.deliveryDelay?.value === 'immediate' ? null : this.orderForm.controls['deliveryDate'].value,
      expiresAt: this.orderForm.controls['expiryDate'].value,
      comment: this.orderForm.controls['comment'].value,
    };
    this.dialogRef.close(data);
  }

  completeEmailControls() {
    const selectedDeliveries = this.#allDeliveries$.value
      .filter((delivery) => this.destinations.value.some((destination) => destination.uuid === delivery.uuid))
      .map((item) => item.email);

    const notificationEmails = selectedDeliveries.length
      ? selectedDeliveries.map((item) => item.notification).reduce((p, c) => p.concat(c))
      : [];
    const deliverEmails = selectedDeliveries.length
      ? selectedDeliveries.map((item) => item.delivery).reduce((p, c) => p.concat(c))
      : [];

    this.notificationEmailsCtrl?.setValue([...new Set(notificationEmails)]);
    this.emailsCtrl?.setValue([...new Set(deliverEmails)]);
  }

  private updateDeliveryTimeOptions() {
    if (!this.deliveryDate?.value || this.deliveryDate?.invalid) {
      return;
    }

    const timeOptions: SelectOption[] = [];
    const selectedDate = moment(this.deliveryDate?.value);

    if (
      selectedDate.isSame(moment(), 'year') &&
      selectedDate.isSame(moment(), 'month') &&
      selectedDate.isSame(moment(), 'day')
    ) {
      const counter = moment(selectedDate);

      counter.minutes(Math.floor(counter.minutes() / 15) * 15);

      while (counter.day() == selectedDate.day()) {
        counter.add(15, 'm');

        const key = counter.format('LT');

        timeOptions.push({
          key,
          label: key,
        });
      }
    } else if (selectedDate.isAfter(moment(), 'day')) {
      for (let h = 0; h < 24; ++h) {
        for (let m = 0; m < 60; m = m + 15) {
          selectedDate.hours(h);
          selectedDate.minutes(m);

          const key = selectedDate.format('LT');

          timeOptions.push({
            key,
            label: key,
          });
        }
      }
    }

    this.deliveryTimeOptions$.next(timeOptions);
  }

  #cacheDeliveryDestinations() {
    this.destinationsService
      .getAll()
      .pipe(
        take(1),
        tap((resp) => this.#allDeliveries$.next(resp.data)),
        this.takeUntilDestroyed(),
        map(({ data }) => data.map(({ configs }) => configs).reduce((prev, curr) => [...prev, ...curr], [])),
      )
      .subscribe((data) => this.#allDeliveriesConfig$.next(data));
  }
}
