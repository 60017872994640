import { PAGE_SIZE_OPTIONS, PageableDataSource } from '@vdms-hq/shared';
import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, defer, map, shareReplay } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { ViewSettingsService } from '../logic/view-settings.service';

export class RouterParamsPagination implements Partial<PageableDataSource> {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected viewSettingsService = inject(ViewSettingsService);

  pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

  changePageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageIndex$ = defer(() =>
    combineLatest([this.changePageIndex$, this.activatedRoute.queryParams]).pipe(
      map(([index, params]) => +params['page'] || index),
    ),
  );
  changePerPageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(48);
  perPageUserSettings$ = this.viewSettingsService.perPageUserSettings$.pipe(shareReplay(1));
  pageSize$ = combineLatest([
    this.changePerPageSize$,
    this.perPageUserSettings$,
    this.activatedRoute.queryParams.pipe(map((params) => +params['perPage'])),
  ]).pipe(map(([defaultSize, user, params]) => params || user || defaultSize));

  async pageChange($event: PageEvent) {
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { perPage: $event.pageSize, page: $event.pageIndex },
      queryParamsHandling: 'merge',
    });
  }
}
