import { Injectable } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetFlatCartItem, CartApiService, CartSummary, SortOptions } from '@vdms-hq/api-contract';
import { CartAssetViewModel, CartStateService } from '@vdms-hq/cart-core';
import { FieldsConfigService } from '@vdms-hq/config';
import { DataProviderService } from '@vdms-hq/selectors';
import { FieldConfigId, ResultDefinitionModel, SelectionManager, TableViewDataSource } from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, combineLatest, EMPTY, of, shareReplay, switchMap, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { CartCheckoutFormService } from '../services/cart-checkout-form.service';
import { RouterParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class CartDataSource extends RouterParamsPagination implements TableViewDataSource<CartAssetViewModel> {
  isLoading$ = this.cartStateService.isUpdating$;
  total$ = new BehaviorSubject<number>(0);

  sortBy$ = new BehaviorSubject<FieldConfigId>('');
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('asc');
  selection: SelectionManager<CartAssetViewModel>;

  cartSummary$ = new BehaviorSubject<CartSummary | null>(null);

  allData$ = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.fieldsConfigService.resultsDefinitions$.pipe(take(1)),
    this.cartStateService.refresh$.pipe(
      tap(() => {
        this.selection.clear();
        this.isLoading$.next(true);
      }),
    ),
    this.cartCheckoutFormService.processSummaryAfterEmailsChanged$,
    this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$,
  ]).pipe(
    switchMap(([page, perPage, orderBy, orderDir, fieldsDefinitions, summary]) => {
      const sort: ResultDefinitionModel | undefined = fieldsDefinitions.find((field) => {
        return field.id == orderBy;
      });

      return this.cartApiService
        .getCartAssets({
          pagination: {
            page,
            perPage,
            orderBy: sort?.results2?.sortObjectPath ?? sort?.results2?.objectPath ?? orderBy,
            orderDir,
          },
        })
        .pipe(
          switchMap((res) => combineLatest([of(res), this.getCartSummary()])),
          map(([response]) => {
            const data = response.data.map((item: AssetFlatCartItem) =>
              CartAssetViewModel.fromCartItem(item, {
                dataProvider: this.dataProvider,
                storageUrlService: this.storageUrlService,
                activatedClientService: this.activatedClientService,
              }),
            );

            return {
              ...response,
              data,
            };
          }),
          catchError((err) => {
            this.isLoading$.next(false);
            this.toastService.error({ id: 'cart', message: err.message });

            return [];
          }),
        );
    }),
    map(({ data, total }) => {
      this.total$.next(total);
      return data;
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  emptyResults$ = combineLatest([this.isLoading$, this.total$]).pipe(
    map(([isLoading, total]) => {
      return isLoading === false && total === 0;
    }),
  );

  constructor(
    private cartApiService: CartApiService,
    private cartCheckoutFormService: CartCheckoutFormService,
    private toastService: ToastService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private activatedClientService: ActivatedClientService,
    private cartStateService: CartStateService,
    private fieldsConfigService: FieldsConfigService,
  ) {
    super();
    this.selection = new SelectionManager<CartAssetViewModel>(this, (item) => item.context?.uuid);
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.isLoading$.next(true);
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.changePageIndex$.next(0);
  }

  getCartSummary() {
    this.isLoading$.next(true);
    return this.cartApiService
      .getCartSummary(
        this.cartCheckoutFormService.orderType,
        this.cartCheckoutFormService.destinationsConfigs,
        this.cartStateService.discountCode ?? undefined,
        this.cartCheckoutFormService.jobsFormValid$.value ? this.cartCheckoutFormService.workflowJobs : undefined,
        this.cartCheckoutFormService.processSummaryAfterEmailsChanged$.value.length
          ? this.cartCheckoutFormService.processSummaryAfterEmailsChanged$.value
          : undefined,
        this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$.value
          ? this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$.value
          : undefined,
      )
      .pipe(
        tap((summary) => {
          this.cartSummary$.next(summary);
          this.cartStateService.discount$.next({
            discountCode: this.cartStateService.discountCode ?? undefined,
            discountStatus: summary.discount_status,
          });
          this.isLoading$.next(false);
        }),
        catchError((err) => {
          this.toastService.error({ id: 'cart', message: err.message });
          this.isLoading$.next(false);
          return throwError(() => err);
        }),
      );
  }
}
