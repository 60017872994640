import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';

type prefix = string;
type name = string;

@Directive({
  selector: '[sharedE2eId]',
  standalone: true,
})
export class E2eIdDirective implements OnInit {
  @Input('sharedE2eId') params?: [prefix, name];

  private el = inject(ElementRef<HTMLElement>);

  ngOnInit(): void {
    if (!this.params) {
      return;
    }
    this.el.nativeElement.setAttribute('data-e2e-id', this.params.join(':'));
  }
}
