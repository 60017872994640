import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { CollectionsService, PaginationAPIModel as Pagination } from '@vdms-hq/api-contract';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { DiacriticsConverter, SelectOption } from '@vdms-hq/shared';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { FormControl, FormGroup } from '@angular/forms';
import { SORT_BY } from '../utils/collections-filters-provider';
import { CollectionsFiltersView } from './collections-multi-ds.service';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { CollectionsRefresh } from '../utils/collections-refresh';
import { ParamsPagination } from '@vdms-hq/view-settings';

export interface CollectionsAddData {
  key: string;
  label: string;
  number_of_assets: number;
  owner: string;
  type: string;
  access_type?: string;
}

@Injectable({ providedIn: 'root' })
export class CollectionsAddDataSource extends ParamsPagination implements TableAdvancedDataSource<SelectOption> {
  private collectionsService = inject(CollectionsService);
  private collectionsRefresher = inject(CollectionsRefresh);
  private toastService = inject(ToastService);
  private authService = inject(AuthService);

  #userId$ = this.authService.id$;

  filters = new FormGroup({
    keyword: new FormControl<string>('', { nonNullable: true }),
    sort: new FormControl<string>('created_desc'),
    collectionType: new FormControl<string>('owned'),
  });

  refresh$ = this.collectionsRefresher.addToCollection$;

  isLoading$ = new BehaviorSubject<boolean>(true);
  total$ = new BehaviorSubject<number>(0);
  emptyResults$ = new BehaviorSubject(false);

  // todo [archer] duplicated code
  values$: Observable<CollectionsFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const selectedSort = SORT_BY.find((item) => item.key === formValue?.sort)?.props;
      const filters = <CollectionsFiltersView>{};

      if (selectedSort) {
        filters.sort = selectedSort;
      }

      switch (formValue?.collectionType) {
        case 'owned':
          filters.collectionType = 'owned';
          break;
        case 'shared':
          filters.collectionType = 'shared';
          break;
        default:
          filters.collectionType = 'all';
          break;
      }

      if (formValue?.keyword) {
        filters.filter = DiacriticsConverter(formValue.keyword);
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  allData$: Observable<CollectionsAddData[]> = combineLatest([
    this.values$,
    this.pageIndex$,
    this.pageSize$,
    this.refresh$,
  ]).pipe(
    withLatestFrom(this.#userId$),
    tap(() => this.isLoading$.next(true)),
    switchMap(([[filters, pageIndex, pageSize], userId]) => {
      const pagination = {
        page: pageIndex,
        perPage: pageSize,
      };

      return this.collectionsService
        .getList(
          {
            filter: filters.filter,
          },
          Pagination.create({
            ...pagination,
            ...filters.sort,
          }),
          filters.collectionType,
        )
        .pipe(
          switchMap((data) => {
            const uuids = data.data.map(({ uuid }) => uuid);
            return combineLatest([of(data), this.collectionsService.getCollectionAccessType(uuids)]);
          }),
          catchError((error) => {
            this.#errorHandler();
            throw error;
          }),
          tap(([data]) => this.total$.next(data.total)),
          tap(([data]) => this.emptyResults$.next(data.total === 0)),
          map(([data, accessTypes]) =>
            data.data.map((collection) => ({
              key: collection.uuid,
              label: collection.name,
              number_of_assets: collection.number_of_assets,
              description: collection.description ?? '',
              owner: collection.owner_name ?? '',
              type: collection.owner === userId ? 'owned' : 'shared',
              access_type: accessTypes.find((type) => type.uuid === collection.uuid)?.access_type,
            })),
          ),
        );
    }),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  refresh(): void {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.controls['sort'].setValue('created_desc');
      this.filters.controls['collectionType'].setValue('owned');
      this.filters.controls['keyword'].setValue('');
    }
  }

  #errorHandler(): Observable<never> {
    this.toastService.error({ id: 'collections', message: 'Error fetching collections' });
    this.isLoading$.next(false);
    this.emptyResults$.next(true);
    return EMPTY;
  }
}
