import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterDefinitionModel, FilterType, ResultDefinitionModel, snakeCaseToCamel } from '@vdms-hq/shared';
import { Timecode } from '@vdms-hq/timecode';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Filters, FilterValue, SortOptions } from '../../../fetcher';
import { PersistenceSearch } from '../../../fetcher/persistence-search';
import { PersistenceSearchParams } from '../../../fetcher/persistence-search-params';
import { PersistenceSearchQuery } from '../../../fetcher/persistence-search-query';
import { AggregatedResponse, Aggregations, ApiPaginatedResponse, ApiResponse } from '../../api.model';
import { ApiService } from '../../api.service';
import { AssetFlat } from '../models/asset-flat.model';
import {
  AssetSearchDateRange,
  AssetSearchExportFieldsParam,
  AssetSearchFilterParam,
  AssetSearchFilterParamWithPageOptions,
  AssetSearchRange,
  isApiDateRange,
  isApiNumberRange,
  isApiTimecodeRange,
} from '../models/search/filters-v2.model';
import { SearchEndpoints } from './endpoints';
import { AdvancedSearchStateService } from '@vdms-hq/ui';

@Injectable()
export class AssetSearchService extends PersistenceSearch<AssetFlat> {
  constructor(private readonly apiService: ApiService, private advancedSearchState: AdvancedSearchStateService) {
    super();
  }

  aiSearch(
    filterDefinitions: FilterDefinitionModel[],
    resultsDefinitions: ResultDefinitionModel[],
    text: string,
  ): Observable<PersistenceSearchParams> {
    const payload = { text };
    return this.apiService
      .post<{ text: string }, ApiResponse<AssetSearchFilterParam>>(`${SearchEndpoints.AI}`, payload)
      .pipe(
        map((response) => JSON.parse(response.data as string) || {}),
        map((params) => this.#apiParamsToPersistenceQueryParams(filterDefinitions, resultsDefinitions, params)),
      );
  }
  search(param: PersistenceSearchParams): Observable<ApiPaginatedResponse<AssetFlat> & AggregatedResponse> {
    const payload = this.persistenceQueryParamsToApiParams(param);
    payload.advanced_mode = !!this.advancedSearchState.state.value;

    let headers = new HttpHeaders();
    if (param.page) {
      headers = headers.append('page', String(param.page));
    }
    if (param.perPage) {
      headers = headers.append('per-page', String(param.perPage));
    }
    if (param.sortBy) {
      headers = headers.append('order-by', param.sortBy.results2.sortObjectPath ?? param.sortBy.results2.objectPath);
    }
    if (param.sortDirection) {
      headers = headers.append('order-dir', param.sortDirection);
    }

    return this.apiService.post<AssetSearchFilterParam, ApiPaginatedResponse<AssetFlat> & AggregatedResponse>(
      `${SearchEndpoints.ROOT}`,
      payload,
      headers,
    );
  }

  export(fields: ResultDefinitionModel[], sortBy: string, sortDir: 'asc' | 'desc') {
    return this.currentParams$.pipe(
      take(1),
      map((params) => this.persistenceQueryParamsToApiParams(params)),
      switchMap((params) => {
        const payload = {
          ...params,
          fields: fields.map((item) => item.results2.exportObjectPath as string).filter(Boolean) ?? [],
        };

        let headers = new HttpHeaders();
        headers = headers.append('per-page', '10000');
        if (sortBy) headers = headers.append('order-by', sortBy);
        if (sortDir) headers = headers.append('order-dir', sortDir);
        return this.apiService.post<AssetSearchExportFieldsParam, ApiPaginatedResponse<AssetFlat> & AggregatedResponse>(
          `${SearchEndpoints.EXPORT}`,
          payload,
          headers,
        );
      }),
    );
  }

  getAggregations() {
    return this.search(new PersistenceSearchParams(new PersistenceSearchQuery(), { page: 0, perPage: 12 })).pipe(
      map(({ aggs }: { aggs: Aggregations }) => aggs),
    );
  }

  #apiParamsToPersistenceQueryParams(
    filterDefinitions: FilterDefinitionModel[],
    resultsDefinitions: ResultDefinitionModel[],
    next: AssetSearchFilterParamWithPageOptions,
  ): PersistenceSearchParams {
    const assignFilterDefValue = (
      value: string | string[] | number | number[] | boolean | AssetSearchRange | AssetSearchDateRange,
      definitionFilterType: FilterType,
    ): FilterValue | null => {
      if (definitionFilterType === FilterType.BITRATE_RANGE || definitionFilterType === FilterType.SIZE_RANGE) {
        const isNumberRange = isApiNumberRange(value);
        if (isNumberRange) {
          return {
            from: isNumberRange?.['from'] ? Number(isNumberRange['from']) : undefined,
            to: isNumberRange?.['to'] ? Number(isNumberRange['to']) : undefined,
          };
        }
      } else if (definitionFilterType === FilterType.DATEPICKER_RANGE) {
        const isDateRange = isApiDateRange(value);
        if (isDateRange) {
          return {
            from: isDateRange?.['from'] ? moment(isDateRange['from']) : undefined,
            to: isDateRange?.['to'] ? moment(isDateRange['to']) : undefined,
          };
        }
      } else if (definitionFilterType === FilterType.TIMECODE_RANGE) {
        const isTimecodeRange = isApiTimecodeRange(value);

        if (isTimecodeRange) {
          return {
            from: isTimecodeRange?.['from'] ? Timecode.fromSeconds(isTimecodeRange['from']) ?? undefined : undefined,
            to: isTimecodeRange?.['to'] ? Timecode.fromSeconds(isTimecodeRange['to']) ?? undefined : undefined,
          };
        }
      }

      if (Array.isArray(value)) {
        return value;
      }

      if (value) {
        return [String(value)];
      }

      return null;
    };

    const getSortOption = (sortBy: string, sortDirection: string): SortOptions | undefined => {
      const definition = resultsDefinitions.find((def) => def.id === sortBy);
      if (!definition) {
        return;
      }
      return {
        field: definition,
        direction: sortDirection.toLowerCase() === 'asc' ? 'asc' : 'desc',
      };
    };

    const filters: Filters = {};
    const apiParams = { ...(next.filters || {}) };
    Object.entries(apiParams).forEach(([key, rawValue]) => {
      const filterDef: FilterDefinitionModel | undefined = filterDefinitions.find(
        (def) => def.filters.objectPath === key,
      );
      if (!filterDef) {
        return;
      }
      const value = assignFilterDefValue(rawValue, filterDef.filters?.type);

      if (!value) {
        return;
      }
      filters[filterDef.id] = {
        filterDef,
        value,
      };
    });

    return new PersistenceSearchParams(
      PersistenceSearchQuery.fromFilters(next.text, filters),
      {
        page: PersistenceSearchParams.defaultPage,
        perPage: next?.filters?.limit || PersistenceSearchParams.defaultPerPage,
      },
      getSortOption(snakeCaseToCamel(next?.filters?.sort_by), next?.filters?.sort_order),
    );
  }

  persistenceQueryParamsToApiParams(next: PersistenceSearchParams): AssetSearchFilterParam {
    return PersistenceSearchParams.toApiParams(next);
  }
}
