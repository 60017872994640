import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, debounceTime, EMPTY, Observable, Subject, switchMap, take, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserDialogResponse } from '../../models/user-dialog.model';
import { Supplier, SupplierService, UserApiService, UserModel } from '@vdms-hq/api-contract';
import { catchError, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DialogResponse, emailPatternValidator, SelectOption } from '@vdms-hq/shared';
import {
  FormSectionComponent,
  UIButtonModule,
  UIConfirmationDialogService,
  UIDialogWrapperModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { v4 as uuidv4 } from 'uuid';
import { EmailExistValidator } from '../../validators/email-exist.validator';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { GenerateUserApiTokenComponent } from '../generate-user-api-token/generate-user-api-token.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { PolicyTypeByView } from '../../logic/policy-type-by-view';

type UserFormGroupType = {
  name: FormControl<string | null>;
  email: FormControl<string>;
  office_phone_number: FormControl<string | null>;
  mobile_number: FormControl<string | null>;
  office_address: FormControl<string | null>;
  timezone: FormControl<string | null>;
  date_format: FormControl<string | null>;
  time_format: FormControl<string | null>;
  supplier: FormControl<string | null>;
};

@Component({
  selector: 'vdms-hq-add-user-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIFormModule,
    UIDialogWrapperModule,
    UIButtonModule,
    FormSectionComponent,
    TranslateModule,
    GenerateUserApiTokenComponent,
    ActivatedClientModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-user-dialog.component.html',
})
export class AddUserDialogComponent extends WithPermissions() implements OnInit, OnDestroy {
  @Input() cancelButton = false;

  activatedClientMail = '';
  userForm = new FormGroup<UserFormGroupType>({
    name: new FormControl<string | null>('', [Validators.required]),
    email: new FormControl<string>('', { nonNullable: true }),
    office_phone_number: new FormControl<string | null>(null),
    mobile_number: new FormControl<string | null>(null),
    office_address: new FormControl<string | null>(null),
    timezone: new FormControl<string | null>(null),
    date_format: new FormControl<string | null>(null),
    time_format: new FormControl<string | null>(null),
    supplier: new FormControl<string | null>(null),
  });
  timezones: SelectOption[] = [{ key: 'UTC+1', label: 'UTC+1' }];
  dateFormats: SelectOption[] = [{ key: 'ISO (2020-02-22)', label: 'ISO (2020-02-22)' }];
  timeFormats: SelectOption[] = [{ key: 'ISO (24H)', label: 'ISO (24H)' }];

  suppliers$: Observable<Supplier[]> = EMPTY;
  suppliersSelectOptions$: Observable<SelectOption[]> = EMPTY;
  userToken$ = new BehaviorSubject<string | null>(null);
  private readonly minimalUserData = {
    picture: '',
    landing_page: '',
    theme: '',
  };

  isUpdating$ = new BehaviorSubject<boolean>(false);

  #destroyed$ = new Subject<void>();

  users: UserModel[] = [];
  userPolicies = new FormControl<string[]>([], { nonNullable: true });
  userGroups = new FormControl<string[]>([], { nonNullable: true });

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent, UserDialogResponse>,
    private confirmationDialog: UIConfirmationDialogService,
    private userApiService: UserApiService,
    private supplierService: SupplierService,
    private authService: AuthService,
    private toast: ToastService,
    private clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
  ) {
    super();
    this.authService.email$.pipe(takeUntil(this.#destroyed$)).subscribe((email) => {
      this.activatedClientMail = email ?? '';
    });
  }

  ngOnInit(): void {
    this.suppliers$ = this.supplierService.getSuppliers();
    this.userToken$.next(this.data?.static_api_token_chars ?? null);
    this.suppliersSelectOptions$ = this.suppliers$.pipe(
      map((suppliers: Supplier[]) =>
        suppliers.map(({ uuid: key, name: label }: { uuid: string; name: string }) => ({ key, label })),
      ),
      map((suppliers) => {
        return [
          {
            key: null,
            label: 'N/A',
          },
          ...suppliers,
        ];
      }),
    );

    const isEdit = !!this.data?.uuid;

    if (isEdit && this.data) {
      this.isUpdating$.next(true);
      this.userApiService
        .getUser(this.data.uuid)
        .pipe(takeUntil(this.#destroyed$))
        .subscribe((user: UserModel) => {
          this.userPolicies.setValue(user?.policies ? [...user.policies].map(({ uuid }) => uuid) : []);
          this.userGroups.setValue(user?.groups ? [...user.groups].map(({ uuid }) => uuid) : []);
          this.isUpdating$.next(false);
        });
    }

    this.setFormData(isEdit);
  }

  ngOnDestroy() {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  setFormData(isEdit: boolean) {
    this.userForm.patchValue({
      name: this.data?.name || null,
      email: this.data?.email || '',
      office_phone_number: this.data?.office_phone_number || null,
      mobile_number: this.data?.mobile_number || null,
      office_address: this.data?.office_address || null,
      timezone: 'UTC+1',
      date_format: 'ISO (2020-02-22)',
      time_format: 'ISO (24H)',
      supplier: this.data?.supplier?.uuid || '',
    });

    this.userForm.controls['email'].valueChanges
      .pipe(
        takeUntil(this.#destroyed$),
        filter((value: string) => value.length > 5),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.userApiService
          .getPaginatedUsersShareMailStrict(value)
          .pipe(take(1))
          .subscribe((users) => {
            this.users = users.data;
            const emailValidators = isEdit
              ? [Validators.required, emailPatternValidator()]
              : [
                  Validators.required,
                  emailPatternValidator(),
                  EmailExistValidator(this.users, this.activatedClientMail),
                ];
            this.userForm.controls['email'].setValidators(emailValidators);
            this.userForm.controls['email'].markAsTouched();
            this.userForm.controls['email'].updateValueAndValidity();
          });
      });
  }

  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }

    const user = {
      ...this.minimalUserData,
      ...this.data,
      ...this.userForm.value,
      default_policy_to_attach: PolicyTypeByView(window.location.href),
    };
    /**
     * @remarks Remove the comment when backend will resolve supplier detaching
     * @todo Think about a generic solution
     */
    user.supplier ||= null;
    if (!user.name || !user.email) {
      return;
    }
    this.isUpdating$.next(true);
    const userData = {
      ...(user as UserModel),
      name: user?.name || '',
      email: user?.email || '',
      uuid: user?.uuid || uuidv4(),
      groups: this.userGroups.value,
      policies: this.userPolicies.value,
    };

    if (user.uuid) {
      this.userApiService.patchUser(user.uuid, userData).subscribe({
        next: () =>
          this.dialogRef.close({ status: DialogResponse.OK, user: userData as unknown as UserModel, edited: true }),
        error: () => {
          this.isUpdating$.next(false);
        },
      });
    } else {
      this.userApiService
        .postUser(userData)
        .pipe(
          catchError((error) => {
            if (error.status === 400) {
              return this.confirmationDialog
                .open({
                  title: 'pages.users.create_existing_title',
                  message: 'pages.users.create_existing_msg',
                })
                .pipe(
                  switchMap((confirmed) => {
                    if (confirmed) {
                      const user = {
                        ...userData,
                        confirm: true,
                      };
                      return this.userApiService.postUser(user).pipe(
                        switchMap((user) => {
                          this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel });
                          return EMPTY;
                        }),
                      );
                    }
                    this.isUpdating$.next(false);
                    return EMPTY;
                  }),
                );
            }
            this.toast.error({
              id: error.status,
              message: error.error.error,
            });
            this.isUpdating$.next(false);
            return EMPTY;
          }),
        )
        .subscribe((user) => {
          this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel });
        });
    }
  }

  abort() {
    this.dialogRef.close({ status: DialogResponse.ABORT });
  }

  generateToken() {
    if (!this.data?.uuid) {
      return;
    }

    this.userApiService.generateApiToken(this.data?.uuid).subscribe(({ token }) => {
      this.toast.success({
        id: 'token_generated',
        message: 'New token has been generated and copied to your clipboard',
      });
      this.clipboard.copy(token);
      this.userToken$.next(token);
    });
  }
}
