import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  UILayoutModule,
  UILoaderModule,
  StatsType,
  UIStatsModule,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIFormModule,
} from '@vdms-hq/ui';
import { EgressStatsDataSource } from '../logic/egress-stats.datasource';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@vdms-hq/shared';
import { EgressListDataSource } from '../logic/egress-list.datasource';
import { EgressListItemViewModel } from '@vdms-hq/api-contract';
import { StringPipesModule } from 'ng-pipes';

@Component({
  selector: 'vdms-hq-egress-stats',
  standalone: true,
  imports: [
    CommonModule,
    UILayoutModule,
    UILoaderModule,
    MatIconModule,
    UIStatsModule,
    SharedModule,
    MultipleDataPresentationComponent,
    StringPipesModule,
    UIFormModule,
  ],
  templateUrl: './egress-stats.component.html',
  styleUrls: ['./egress-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EgressStatsComponent {
  public statsDataSource = inject(EgressStatsDataSource);
  public listDataSource = inject(EgressListDataSource);

  protected readonly today = new Date();
  protected readonly StatsType = StatsType;

  configuration: MultipleViewConfiguration<EgressListItemViewModel> = {
    multiView: {
      defaultView: 'tableAdvanced',
      fitToVisibleArea: false,
    },
    tableAdvanced: {
      columns: [
        { id: 'orderTitle', label: 'Order Title/Contract Name', valuePath: 'orderTitle', sortable: true },
        { id: 'purchaseOrderNumber', label: 'Purchase Order Number', valuePath: 'purchaseOrderNumber', sortable: true },
        { id: 'userEmail', label: 'User Email', valuePath: 'userEmail', sortable: true },
        { id: 'destination', label: 'Destination', valuePath: 'destination', sortable: true },
        { id: 'country', label: 'Country', valuePath: 'country', sortable: true },
        {
          id: 'status',
          label: 'Status',
          valuePath: 'status',
          viewFormat: {
            pills: {
              success: 'done',
              running: 'cold-blue',
            },
          },
          sortable: true,
        },
      ],
      columnsEnabled: ['orderTitle', 'purchaseOrderNumber', 'userEmail', 'destination', 'country', 'status'],
    },
  };
}
