import { ValidatorFn } from '@angular/forms';
import { ValueFormat } from '../models/value-formatter.model';
import { ValueDisplayFormatConfig } from '../models/value-renderer.model';
import { FieldType } from './field-type';
import { FilterType } from './filter-type';

export type FieldConfigId = string;
export type SourceListKey = string;
export type FilterObjectPath = string;

export enum ResourceModel {
  ASSET_BROWSE = 'ASSET_BROWSE',
  ASSET_CART = 'ASSET_CART',
  ASSET_ORDER = 'ASSET_ORDER',
  ASSET_ORPHAN = 'ASSET_ORPHAN',
  ASSET_PREVIEW_REQUEST = 'ASSET_PREVIEW_REQUEST',
  ASSET_SHARED_PACK = 'ASSET_SHARED_PACK',
  ASSET_DELETED = 'ASSET_DELETED',
  ASSET_LICENSED_PACKAGE = 'ASSET_LICENSED_PACKAGE',

  CLIENT = 'CLIENT',
  RIGHTS_CONTRACT = 'RIGHTS_CONTRACT',
  RIGHTS_PARTNER = 'RIGHTS_PARTNER',
  PREVIEW_REQUEST = 'PREVIEW_REQUEST',
  LICENSED_PACKAGE = 'LICENSE_PACKAGE',
  RATING = 'RATING',
  SYSTEM = 'SYSTEM',
  DISCOUNT = 'DISCOUNT',
  USER = 'USER',
  CLIENT_USER = 'CLIENT_USER',
  SELECT_OPTION = 'SELECT_OPTION',

  DELIVERY_PACK = 'DELIVERY_PACK',
  DELIVERY_UPLOAD_JOB = 'DELIVERY_UPLOAD_JOB',
}

interface BaseDefinitionModel {
  featureDate?: Date;
  id: FieldConfigId;
  isModified?: boolean;
  isCustomDefinition?: boolean;
  resource: ResourceModel[];
  sourceListKey?: SourceListKey;
  label: string;
  format: ValueFormat;
  input?: FieldDefinitionInput;
  results2?: FieldDefinitionResults2;
  filters?: ClientDefinitionFilters;
  appliedFilters?: ClientDefinitionFilters;
  useAsFilename?: boolean;
}

export interface InputDefinitionModel extends BaseDefinitionModel {
  input: FieldDefinitionInput;
}

export interface FilterDefinitionModel extends BaseDefinitionModel {
  filters: ClientDefinitionFilters;
}

export interface ResultDefinitionModel extends BaseDefinitionModel {
  results2: FieldDefinitionResults2;
}

export type FieldDefinitionModel = FilterDefinitionModel | ResultDefinitionModel | InputDefinitionModel;

export const isVisibleInTable = (field: FieldDefinitionModel): field is ResultDefinitionModel =>
  'results2' in field && !!field['results2'];
export const isVisibleInForm = (field: FieldDefinitionModel): field is InputDefinitionModel =>
  'input' in field && !!field['input'];
export const isVisibleSearch = (field: FieldDefinitionModel): field is FilterDefinitionModel =>
  'filters' in field && !!field['filters'];

interface FieldDefinitionInput {
  objectPath?: string;
  type: FieldType;
  readonly: boolean;
  mask?: string;
  validators?: ValidatorFn[];
  format?: ValueFormat;
  readPermission?: {
    permissions: string[];
    comparator: 'some' | 'every';
  };
  writePermission?: {
    permissions: string[];
    comparator: 'some' | 'every';
  };
}

type PillColor =
  | 'cold-blue'
  | 'orange'
  | 'white-dashed'
  | 'yellow-dashed'
  | 'orange-light'
  | 'yellow'
  | 'done-light'
  | 'done'
  | 'failed'
  | '';

export interface ViewFormat {
  greyIfEmpty?: boolean;
  greyIfEquals?: string;
  maxVisibleValues?: number;
  foldValues?: boolean;
  pills?: {
    [key: string]: PillColor;
  };
}

export interface DataSelector {
  value: string;
  label: string;
  default?: boolean;
}

interface FieldDefinitionResults2 {
  objectPath: string;
  exportObjectPath?: string;
  sortObjectPath?: string;
  sortable: boolean;
  viewFormat?: ValueDisplayFormatConfig;
}

interface ClientDefinitionFilters {
  objectPath: FilterObjectPath;
  aggregationKey?: string;
  validFormat: 'keyword' | 'range';
  type: FilterType;
  mask?: string;
  singleValue?: boolean;
}
